import { useState, useEffect } from "react";
import * as val from "../../../utils/validations";
import countryList from "../../../utils/regionCodes";
import { countryCode2TaxIdType } from "../../../utils/configurations";
import { useDispatch, useSelector } from "react-redux";
import { getInfoById } from "../../../redux/actions";

function useFormData() {
  const props = useSelector((state) => ({
    tradeInfo: state.tradeInfo,
    method: state.method,
    payChannel: state.payChannel,
  }));

  const dispatch = useDispatch();

  const [form, setForm] = useState({
    isGlobal: false,
    name: { value: "", valid: true, max: 80, checked: false, show: false, validityFunction: val.checkName },
    firstName: { value: "", valid: true, max: 80, checked: false, validityFunction: val.checkFirstName },
    lastName: { value: "", valid: true, max: 80, checked: false, validityFunction: val.checkLastName },
    idType: "",
    taxId: { value: "", valid: true, checked: false, validityFunction: val.checkTaxId },
    email: { value: "", valid: true, max: 80, checked: false, show: false, validityFunction: val.checkEmail },
    phone: {
      value: "",
      valid: true,
      cCode: "",
      countryObj: {},
      max: {
        BRA: 17,
        MEX: 16,
        CHL: 15,
        COL: 15,
        PER: 15,
        ECU: 16,
        PAN: 14,
        CRI: 14,
        SLV: 14,
        GTM: 14,
        NIC: 14,
        SGP: 13,
        HKG: 14,
        PHP: 17,
        KOR: 17,
        IDN: 18,
        MYS: 17,
        THA: 16,
        SAU: 17,
        ARE: 17,
        KWT: 14,
        QAT: 14,
        OMN: 14,
        IND: 16,
        EGY: 17,
        RUS: 16,
        EUP: 20,
      },
      checked: false,
      show: false,
      validityFunction: val.checkPhone,
    },
    cardNumber: { value: "", valid: true, checked: false, validityFunction: val.checkCardNumber },
    expYear: { value: "", valid: true, checked: false, validityFunction: val.checkYear },
    expMonth: { value: "", valid: true, checked: false, validityFunction: val.checkMonth },
    cvc: { value: "", valid: true, max: 4, checked: false, validityFunction: val.checkCvc },
    country: { value: "", valid: true, checked: false, validityFunction: val.checkCountry },
    state: { value: "", valid: true, checked: false, max: 50, validityFunction: val.checkState },
    city: { value: "", valid: true, checked: false, max: 50, validityFunction: val.checkCity },
    postalCode: { value: "", valid: true, max: 10, checked: false, validityFunction: val.checkPostalCode },
    street: { value: "", valid: true, max: 50, checked: false, validityFunction: val.checkStreet },
    no: { value: "", valid: true, max: 10, checked: false, validityFunction: val.checkNo },
    // todito_nip: { value: "", valid: true, max: 4, checked: false },
    todito_cardNo: { value: "", valid: true, max: 18, checked: false, validityFunction: val.checkToditoCardNo },
    todito_pin: { value: "", valid: true, max: 4, checked: false, validityFunction: val.checkToditoPin },
    mach_cardNo: { value: "", valid: true, max: 16, checked: false, validityFunction: val.checkMachCardNo },
    vita_password: { value: "", valid: true, max: 20, checked: false, validityFunction: val.checkVitaPassword },
    vita_id: { value: "", valid: true, max: 20, checked: false, validityFunction: val.checkVitaId },
    installments: { stage: null, amount: null, amount_each: null, rate: null },
    source: { value: "", valid: true, max: 150, checked: false, validityFunction: val.checkSource },
    boleto_cep: { value: "", valid: true, checked: false, validityFunction: val.checkCEP },
    bill_country: { value: "", valid: true, checked: false, validityFunction: val.checkBillCountry },
    bill_state: { value: "", valid: true, checked: false, max: 50, validityFunction: val.checkBillState },
    bill_city: { value: "", valid: true, checked: false, max: 50, validityFunction: val.checkBillCity },
    bill_street: { value: "", valid: true, checked: false, max: 50, validityFunction: val.checkBillStreet },
    bill_no: { value: "", valid: true, max: 10, checked: false, validityFunction: val.checkBillNo },
    bill_postalCode: { value: "", valid: true, max: 10, checked: false, validityFunction: val.checkBillPostalCode },
    isSameAddress: true,
    delivery_country: { value: "", valid: true, checked: false, validityFunction: val.checkDeliveryCountry },
    delivery_state: { value: "", valid: true, checked: false, max: 50, validityFunction: val.checkDeliveryState },
    delivery_city: { value: "", valid: true, checked: false, max: 50, validityFunction: val.checkDeliveryCity },
    delivery_street: { value: "", valid: true, checked: false, max: 50, validityFunction: val.checkDeliveryStreet },
    delivery_no: { value: "", valid: true, max: 10, checked: false, validityFunction: val.checkDeliveryNo },
    delivery_postalCode: {
      value: "",
      valid: true,
      max: 10,
      checked: false,
      validityFunction: val.checkDeliveryPostalCode,
    },
    account_number: { type: "C", value: "", valid: true, checked: false, validityFunction: val.checkAccountNumber },
    bank_list: { value: "", valid: true, checked: false, validityFunction: val.checkBankList },
    voucher_number: { value: "", valid: true, max: 16, checked: false, validityFunction: val.checkVoucherNumber },
    address: { value: "", valid: true, checked: false, max: 80, validityFunction: val.checkAddress },
    isPolicyAccepted: true,
    submitPost: null,
  });

  useEffect(() => {
    const region = props.tradeInfo?.app_info?.region;
    // console.log("region status: ", region);
    const countryObj = countryList.find((item) => item?.alpha3_code === region) || {
      country_name: "United States",
      alpha2_code: "US",
      alpha3_code: "USA",
      phone_code: "+1",
    };
    // console.log("idType set 1");
    setForm((prev) => {
      return {
        ...prev,
        idType: countryCode2TaxIdType[region]?.[0],
        phone: { ...prev.phone, value: countryObj.phone_code, countryObj: countryObj },
      };
    });
  }, [props.tradeInfo?.app_info?.region, form.isGlobal]);

  useEffect(() => {
    if (props.method === "PIX") {
      dispatch(getInfoById(setForm, { type: form.idType, id: form.taxId.value }));
    }
  }, [form.taxId.value, props.method, form.idType]);

  useEffect(() => {
    const isGlobal =
      props.tradeInfo?.app_info?.region === "GLB" ||
      props.tradeInfo?.app_info?.region === "EUP" ||
      props.method === "AlipayPlus";

    setForm((prev) => {
      return {
        ...prev,
        isGlobal: isGlobal,
      };
    });
  }, [props.method, props.payChannel, props.tradeInfo?.app_info?.region]);

  return [form, setForm];
}

export default useFormData;
