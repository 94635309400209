import React from "react";
import { useTranslation } from "react-i18next";
import Back from "../../../img/back.png";
import "./PrivacyPolicy.scss";
function PrivacyPolicy({ back = () => {}, region, isPrivacyPolicy = true, provider }) {
  const { t } = useTranslation();
  return (
    <div className="privacyPolicy">
      <div className="privacyPolicy__content">
        <img className="privacyPolicy__content__back" src={Back} alt="" onClick={back} />
        {isPrivacyPolicy ? (
          region === "BRA" ? (
            <div>
              <h1>POLÍTICA DE PRIVACIDADE</h1>
              <h2>1. Objetivo deste documento</h2>
              <p>
                Esta Política de Privacidade (“Política”) indica como os dados pessoais do Usuário são tratados em razão
                do uso dos Serviços de processamento de pagamentos da LUXTAK, na compras realizadas pelos Usuários nos
                sites dos lojistas cadastrados. Este documento é parte integrante dos Termos e Condições da LUXTAK, que
                contêm uma visão geral dos Serviços. Esta Política se refere unicamente aos Serviços prestados pela
                LUXTAK. Os produtos e serviços que o Usuário adquirir dos lojistas online estão sujeitos aos seus
                próprios termos de uso e políticas de privacidade. Além disso, os métodos de pagamento também podem
                estar sujeitos aos termos de uso e políticas de privacidade determinados pelas instituições financeiras
                e emissores de cartões.
              </p>
              <h2>2. Público Alvo e Aceitação - Usuários</h2>
              <p>
                Esta política se aplica a todos os usuários de serviços da LUXTAK (“Usuários”), e descreve como nós
                podemos coletar, usar, compartilhar e manter os Dados Pessoais que obtemos on-line. Coletamos Dados
                Pessoais quando o Usuário:
              </p>
              <ul>
                <li>Realiza o pagamento pela compra online utilizando os meios de pagamento da LUXTAK;</li>
                <li>
                  Utiliza nossa plataforma de Suporte e Atendimento ao Cliente, ou outros meios de comunicação conosco;
                </li>
                <li>Visita ou usa o web-Site da LUXTAK;</li>
                <li>Se inscrever ou se registrar para receber nossas ofertas de marketing ou outros serviços;</li>
                <li>Receber ou responder às nossas comunicações eletrônicas;</li>
                <li>Visualizar ou clicar em nossos anúncios ou outros conteúdos on-line;</li>
                <li>
                  Interagir conosco através de sites de redes sociais e outros aplicativos, serviços e websites; e
                </li>
                <li>
                  em consultas e validações de dados obrigatórias conforme regulação do Banco Central do Brasil em razão
                  da utilização dos nossos serviços de intermediação de pagamentos.
                </li>
              </ul>
              <p>
                Nesta declaração, também explicamos como podemos combinar Dados Pessoais Online com Outras Informações
                que coletamos e como usamos tais Dados Pessoais combinados.
              </p>
              <p>
                Ao aceitar os termos dessa Política de Privacidade, o Usuário está ciente de que a controladora dos seus
                dados pessoais, ou seja, a empresa responsável por tomar as decisões sobre o tratamento dos seus dados
                pessoais, será a Luxtak Intermediação e Agenciamento de Negócios LTDA., inscrita no CNPJ sob nº
                23.010.551/0001-31, com sede na Cidade de São Paulo, Estado de São Paulo, na Alameda Santos, 1773, sala
                1205, São Paulo, SP, CEP 01419-100.
              </p>
              <h2>3. Base Normativa Referencial</h2>
              <p>Lei nº 13.709/2018 (Lei Geral de Proteção de Dados Pessoais, ou "LGPD")</p>
              <h2>4. Dos Serviços Prestados pela LUXTAK</h2>
              <p>
                A LUXTAK é uma empresa de processamento de pagamentos que fornece meios de pagamentos locais em sites
                internacionais e nacionais (“Merchants”).
              </p>
              <p>
                Nas compras realizadas pelo Usuário nos Merchants que processam pagamentos com a LUXTAK, nós atuamos
                como operador de seus dados pessoais: somos responsáveis por processar o pagamento de compras de
                serviços ou produtos realizados por você nos sites que lhe oferecem nossos meios de pagamento.
              </p>
              <p>
                Ainda, a LUXTAK pode agir como controlador dos seus dados pessoais em determinadas situações para fins
                comerciais próprios, como quando enviamos notificações sobre as compras realizadas por você com os meios
                de pagamento da LUXTAK ou quando tomamos medidas preventivas e reativas para identificar, prevenir e
                remediar fraudes e ameaças de segurança.
              </p>
              <h2>5. Dados que coletamos</h2>
              <p>
                Alguns dados que utilizamos para poder prestar nossos Serviços podem ser considerados dados pessoais –
                isso é, informações relacionadas ao Usuário que, individualmente ou em conjunto, podem identificá-lo.
              </p>
              <p>Coletamos dados pessoais nas seguintes circunstâncias:</p>
              <ul>
                <li>
                  Quando você utiliza os serviços de intermediação de pagamentos da Luxtak: coletamos suas informações
                  de cadastro e contato, incluindo seu nome, CPF, número de telefone, e-mail, e podemos coletar também
                  informação de endereço, data de nascimento e outras informações semelhantes.{" "}
                </li>
                <li>
                  Quando você acessa nosso site ou visita uma página da web que está usando nosso serviço: coletamos
                  informações transmitidas automaticamente do(s) dispositivo(s) que você usa para acessar nosso site ou
                  dos sites que integram nosso serviço, como seu endereço IP, identificador de dispositivo exclusivo,
                  informações do navegador e informações do sistema (por exemplo, sistema operacional.) Essas
                  informações, sozinhas ou combinadas com outras informações, podem permitir que você seja identificado.
                </li>
                <li>
                  Quando você conclui uma transação usando nosso serviço: durante o curso de uma transação de pagamento
                  por uma compra de produto ou serviço nos sites parceiros, pode ser necessário a inserção de algumas
                  informações sobre você. Essas informações podem incluir seu nome completo, endereço, data de
                  nascimento e detalhes sobre a forma de pagamento que você está usando, como dados bancários de
                  pagamento e para recebimento de valores.
                </li>
                <li>
                  Quando nos é fornecido por fontes de terceiros em conexão com o uso de nosso serviço: nossos parceiros
                  e Instituições Financeiras podem nos enviar qualquer informação que você tenha fornecido a eles,
                  incluindo seus dados pessoais (por exemplo, nome, informações de contato, etc.) e detalhes
                  relacionados às suas compras e atividades passadas e atuais em seu site. Além disso, podemos receber
                  informações sobre você do provedor do método de pagamento que você usa em nosso serviço na medida em
                  que tais informações sejam necessárias para processar sua transação, ou se posteriormente se tornarem
                  necessárias durante uma investigação de transações fraudulentas ou suspeitas.
                </li>
                <li>
                  (V)Quando você se comunica conosco: você pode precisar fornecer informações adicionais sobre você e
                  sua transação na comunicação. Além disso, podemos entrar em contato com você para solicitar uma prova
                  de identidade pessoal, como uma foto de um documento de identificação legal, a fim de garantir que sua
                  transação seja válida ou na medida em que de outra forma possa ser necessária para cumprir nossas
                  obrigações legais como instituição financeira.
                </li>
              </ul>
              <p>Os dados pessoais coletados, conforme acima destacados, enquadram-se nas seguintes categorias:</p>
              <ul>
                <li>
                  Dados de Identidade: incluem informações sobre sua identidade, como nome completo, número de
                  identificação fiscal (RG e CPF), data de nascimento, endereço, e-mail, nome da mãe e número de
                  telefone.
                </li>
                <li>
                  Dados Financeiros: incluem informações sobre detalhes do pagamento, informações bancárias e
                  informações dos métodos de pagamento utilizados por você para comprar nos e-commerces que são nossos
                  clientes.
                </li>
                <li>
                  Dados Técnicos: incluem informações sobre seu IP, hora e data de acesso, geolocalização, dados sobre o
                  seu dispositivo de acesso e cookies.
                </li>
                <li>
                  Dados de Uso: Informações sobre como você usa nosso Serviço, como perfil e comportamento de compra e
                  volume de transações.
                </li>
                <li>Dados Biométricos: incluem informações como fotos de seus documentos e fotos pessoais.</li>
              </ul>
              <h2>6. Finalidade</h2>
              <p>
                A principal Finalidade do tratamento dos seus dados é para prestar nossos Serviços, incluindo
                processamento de pagamentos, estornos e reembolsos, envio de notificações sobre transações, verificação
                de identidade, identificação e prevenção de fraudes e ameaças de segurança, análise de dados, manutenção
                de sistemas, hospedagem de dados e cumprimento de nossas obrigações legais e regulatórias.
              </p>
              <p>
                E também para gerenciar nosso relacionamento com você, incluindo atendimento via nossos canais de
                comunicação e de atendimento ao cliente.
              </p>
              <h2>7. Como tratamos seus dados pessoais</h2>
              <p>
                Para prestar nossos Serviços, tratamos seus dados pessoais de acordo com as instruções do site em que
                você realizou a compra de serviços ou produtos utilizando meios de pagamento da LUXTAK, de acordo com
                esta Política ou de acordo com a legislação de proteção de dados aplicável.
              </p>
              <p>Usamos seus dados apenas pelos seguintes motivos:</p>
              <ul>
                <li>
                  Para oferecer o serviço Luxtak: usaremos suas informações pessoais conforme necessário para fornecer o
                  serviço Luxtak, incluindo na medida necessária para processar a transação que você iniciou, verificar
                  sua identidade, autenticar seu acesso a uma conta Luxtak e nos comunicar com você sobre o serviço.
                </li>
                <li>
                  Para gerenciar o risco e proteger você, o site e o serviço Luxtak: a prevenção de fraude é uma parte
                  crítica da prestação de serviços de pagamento e usamos suas informações para nos ajudar a detectar e
                  prevenir fraudes.
                </li>
                <li>
                  Para cumprir nossas obrigações legais, incluindo todas as leis aplicáveis de combate à lavagem de
                  dinheiro, leis de financiamento do terrorismo, regulamentos de serviços financeiros e nossas
                  obrigações contratuais para com os parceiros terceirizados que fornecem ou ajudam a fornecer qualquer
                  método de pagamento que você use no nosso serviço.
                </li>
                <li>
                  Para a realização de câmbio, quando a transação de pagamento estiver relacionada a compra
                  internacional, isto é, sua compra for realizada junto a um fornecedor estrangeiro, seus dados de
                  identificação assim como os dados relacionados ao pagamento poderão ser compartilhados com o Banco
                  Central, para registro da transação, nos termos da legislação aplicável.
                </li>
                <li>
                  Para outras atividades internas: podemos precisar usar suas informações pessoais para cumprir nossos
                  contratos e termos de serviço e monitorar a atividade no site.
                </li>
              </ul>
              <p>
                Apenas utilizaremos seus dados pessoais para as finalidades para as quais os coletamos, a menos que seja
                necessário utilizá-lo em razão de obrigação legal ou imposta por autoridade. Se precisarmos tratar seus
                dados pessoais com uma nova finalidade não relacionada originalmente, nós notificaremos você a respeito.
              </p>
              <h2>8. Como divulgamos suas informações fora da LUXTAK</h2>
              <p>
                Seus dados podem ser divulgados a terceiros em qualquer uma ou em todas as seguintes circunstâncias:
              </p>
              <ul>
                <li>
                  Quando nossos parceiros de pagamento precisam processar uma transação que você iniciou: temos
                  contratos com bandeiras, adquirentes, bancos e outras instituições financeiras para todos os métodos
                  de pagamento que oferecemos em nosso serviço. Quando você autorizar uma transação, transmitiremos ao
                  terceiro relevante todas as informações necessárias para processar essa transação. As informações
                  necessárias variam de acordo com o tipo de pagamento, mas podem incluir seu nome, CPF, endereço e
                  detalhes da compra que você está tentando fazer.
                </li>
                <li>
                  Quando terceiros que nos fornecem serviços relacionados à LUXTAK os exigirem a fim de fornecer
                  serviços, tais como nossos provedores de hospedagem de servidores e auditores independentes que
                  contratamos para fins de análise de nossa conformidade com a lei ou independente relevante padrões.
                </li>
                <li>
                  Na medida exigida por lei: compartilharemos suas informações com terceiros na medida em que formos
                  obrigados a fazê-lo por lei. Por exemplo, somos obrigados por lei a passar por certas auditorias de
                  rotina, que podem exigir que compartilhemos suas informações com os auditores terceirizados que
                  contratamos em relação a esses requisitos. Além disso, podemos ter que divulgar suas informações
                  quando recebermos uma intimação válida ou outra solicitação de aplicação da lei, ou quando a lei
                  exigir que notifiquemos afirmativamente a aplicação da lei para evitar danos ou atividades ilegais. A
                  necessidade de todas essas divulgações será determinada a nosso exclusivo critério. Para obter mais
                  informações sobre nossos procedimentos relacionados a solicitações legais, consulte nossa política de
                  aplicação da lei.
                </li>
                <li>
                  Na medida em que exigido por Autoridade Competente: é possível que Autoridades solicitem informações
                  sobre você e/ou transações de pagamento por você contratadas com a Luxtak. Neste caso, para o
                  cumprimento da solicitação, seus dados pessoais e financeiros, assim como relacionados com a transação
                  de pagamento poderão ser compartilhados.
                </li>
                <li>
                  Na medida em que nossos interesses comerciais legítimos exigem que o façamos, por exemplo,
                  transmitindo-o a nossos parceiros terceiros para inclusão em suas listas negras ou listas de
                  comerciantes desligados, para nos ajudar a nos envolver em nossos esforços de redução de fraude, para
                  apoiar nosso atividades de governança corporativa ou facilitar a venda ou outra transferência de todo
                  ou parte de nosso negócio, ou para nos proteger ou proteger nosso serviço.
                </li>
              </ul>
              <p>
                Como sua privacidade é importante para nós, tomamos medidas para garantir que todas as entidades com as
                quais compartilhamos suas informações tenham implementado fortes práticas de privacidade e proteção de
                dados em um nível comparável ao que empregamos.
              </p>
              <p>
                Podemos compartilhar seus dados com empresas do mesmo grupo econômico, processadores de pagamento,
                arranjos de pagamento com cartões e instituições financeiras parceiros, agentes de prevenção a fraudes e
                validação de dado.
              </p>
              <p>
                Seus dados pessoais podem ser compartilhados, ainda, com órgãos estatais e agências reguladoras, para o
                cumprimento de obrigações legais e regulatórias.
              </p>
              <h2>9. Como protegemos sua informação </h2>
              <p>
                Apenas tratamos seus dados pessoais conforme estabelecido nesta política. Não compartilhamos,
                fornecemos, licenciamos, vendemos ou transferimos seus dados pessoais a ninguém, em nenhuma hipótese,
                com fins de obter lucro ou de forma contrária ao contido nesta Política.
              </p>
              <p>
                O objetivo da LUXTAK é fornecer a você uma experiência de pagamento online segura e conveniente. Sempre
                protegemos a segurança de suas informações usando a tecnologia Secure Sockets Layer (SSL) para cada
                transmissão que criptografa as informações inseridas. Todas as compras feitas com cartão de crédito ou
                pagamento direto alternativo devem usar um navegador habilitado para SSL, para proteger a
                confidencialidade de suas informações pessoais e de cartão de crédito enquanto estão sendo transmitidas
                pela Internet. Quando você insere informações confidenciais (como detalhes da conta da carteira
                eletrônica, número de telefone celular, número do cartão de crédito ou sua senha) e faz um pedido
                on-line, criptografamos essas informações usando a tecnologia Secure Socket Layer (SSL).
              </p>
              <p>
                Os usuários podem verificar a segurança de sua conexão com a Internet observando a barra de endereços na
                janela do navegador enquanto fazem pedidos online. Se você identificar uma chave intacta ou um cadeado
                fechado, a SSL está ativa e o servidor está seguro. Ao acessar um servidor seguro, os primeiros
                caracteres do endereço do site mudarão de "http" para "https".
              </p>
              <p>
                Para transações com cartão de crédito, transmitiremos com segurança o número completo do cartão de
                crédito para processamento pela empresa de cartão de crédito. Apenas revelaremos os últimos 4 dígitos do
                número do seu cartão de crédito ao imprimir uma confirmação de pedido. Por motivos de segurança, não
                salvamos ou armazenamos as informações do seu cartão de crédito, pois elas são usadas apenas para
                concluir a transação segura. O número do seu cartão de crédito e outras informações confidenciais não
                serão armazenadas após a conclusão da transação para ajudar a proteger as informações do seu cartão de
                crédito. Para se proteger ainda mais contra o acesso não autorizado à sua conta e computador,
                certifique-se de sair de sua conta quando terminar de utilizar o serviço.
              </p>
              <h2>10. Compartilhamento de Dados por solicitação de Autoridade Competente</h2>
              <p>
                É possível que Autoridades solicitem informações sobre você e/ou transações de pagamento por você
                contratadas com a Luxtak. Neste caso, para o cumprimento da solicitação, seus dados pessoais e
                financeiros, assim como relacionados com a transação de pagamento poderão ser compartilhados.
              </p>
              <p>
                Neste caso, somente compartilharemos o mínimo de dados e informações necessárias a cumprir a solicitação
                da Autoridade, e sempre requisitaremos o tratamento estrito e confidencial da informação e dados
                fornecidos.
              </p>
              <p>
                Para o atendimento da solicitação, a Autoridade deverá enviar solicitação formal, por meio de
                comunicação oficial, seja por carta ou email institucional.
              </p>
              <p>Deverá, ainda, ser apresentada por meio de Ofício devidamente assinado pela Autoridade.</p>
              <p>
                A resposta ao Ofício somente será enviada por carta assinada pela Luxtak, e/ou email institucional, para
                o endereço físico ou email da autoridade, também institucional.
              </p>
              <h2>11. Seus direitos como titular de dados</h2>
              <p>
                Em razão da regulação aplicável para os Serviços de Meios de Pagamento que prestamos, estamos sujeitos a
                obrigações de coleta, manutenção e validação de dados pessoais, a fim de garantir o cumprimento da
                legislação de prevenção à lavagem de dinheiro e outras determinadas pelo Banco Central do Brasil. Em
                razão disso, para o fornecimento de nossos serviços não nos baseamos no consentimento como base legal
                para o tratamento de seus dados pessoais.
              </p>
              <p>
                Apenas para o tratamento em outras finalidades, como o envio de comunicações de marketing via e-mail,
                mensagem de texto ou outros canais de comunicação digitais, solicitamos o seu consentimento. Também
                poderemos enviar estas comunicações de marketing direto com base no legítimo interesse se entendermos
                que você possa se interessar por produtos e serviços que podemos lhe oferecer. Caso isso ocorra, você
                tem o direito de revogar o seu consentimento, ou de se opor ao legítimo interesse da Luxtak, nas
                próprias comunicações da Luxtak recebidas por você, entrando em contato conosco.
              </p>
              <p>
                Além disso, destacamos abaixo seus direitos como titular de dados que podem ser exercidos entrando em
                contato conosco:
              </p>
              <ul>
                <li>
                  Direito de acesso. Você pode requisitar e receber uma cópia dos seus dados pessoais que armazenamos.
                  Além disso, você pode solicitar esclarecimentos sobre como coletamos os seus dados pessoais, quais
                  critérios utilizamos, qual a finalidade de tratamento e com quem compartilhamos os seus dados
                  pessoais.
                </li>
                <li>
                  Direito de retificação. Você pode solicitar a retificação de dados pessoais que estejam incompletos,
                  inexatos ou desatualizados, mediante a verificação de validade dos dados que você nos fornecer.
                </li>
                <li>
                  Direito de anonimização, bloqueio ou eliminação. Você pode solicitar a anonimização, bloqueio ou
                  eliminação de dados pessoais a que você tenha dado o consentimento para o tratamento, e que você
                  entenda que estão sendo tratados de maneira contrária a esta Política ou em desconformidade com a
                  legislação de proteção de dados pessoais aplicável. Esta solicitação será atendida desde que não
                  contrarie legislação aplicável.
                </li>
                <li>
                  Direito de oposição. Você pode se opor ao tratamento de seus dados pessoais que não sejam realizados
                  com base no seu consentimento, caso você entenda que tal tratamento está violando seus direitos.
                  Nesses casos, podemos demonstrar que temos motivos legítimos para tratar os seus dados pessoais
                  conforme esta Política e para prestarmos nossos Serviços adequadamente.
                </li>
                <li>
                  Direito de exclusão. Você pode solicitar a exclusão dos seus dados pessoais armazenados pela Luxtak,
                  tratados com o seu consentimento, que não sejam mais necessários ou relevantes para a prestação dos
                  Serviços, desde que não tenhamos outra razão para mantê-los, como para o cumprimento de obrigação
                  legal ou regulatória de retenção dos dados ou para resguardar os direitos da Luxtak.
                </li>
                <li>
                  Direito de não fornecimento do consentimento. Você pode se recusar ao tratamento de dados pessoais
                  realizado com base no seu consentimento a qualquer momento. Porém, se você retirar o seu
                  consentimento, é possível que não possamos oferecer parte dos Serviços adequadamente.
                </li>
                <li>
                  Direito de revisão. Você pode solicitar a revisão de decisões tomadas unicamente com base em
                  tratamento automatizado, caso entenda que elas estejam afetando seus interesses.
                </li>
                <li>
                  Direito de portabilidade. Você tem direito a solicitar a portabilidade de seus dados pessoais em
                  formato estruturado e interoperável.
                </li>
              </ul>
              <p>
                Para apresentar suas manifestações, entre em contato conosco através de nosso canal de atendimento que
                está divulgado e acessível pelo website.
              </p>
              <h2>12. Por quanto tempo os dados pessoais são armazenados?</h2>
              <p>
                Seus dados pessoais são armazenados somente pelo tempo que for necessário para cumprir com as
                finalidades para as quais os coletamos, inclusive para fins de cumprimento de quaisquer obrigações
                legais e regulamentares, contratuais, de prestação de contas ou requisição de autoridades competentes,
                observado o prazo mínimo de 5 (cinco) anos contado do do ano seguinte ao término do nosso relacionamento
                com você, em atendimento às normativas do sistema financeiro aplicáveis.
              </p>
              <p>
                Você poderá solicitar a exclusão de seus dados pessoais, e realizaremos a exclusão dos dados que não
                tenhamos obrigatoriedade legal de armazenamento, ou os excluiremos após decorrer o prazo legal de
                armazenamento, ou por ordem de autoridade competente.
              </p>
              <h2>13. Cookies</h2>
              <p>
                Cookies são arquivos de texto colocados em seu computador para coletar informações de log padrão da
                Internet e informações de comportamento do visitante. Quando você visita nossos sites, podemos coletar
                informações suas automaticamente por meio de cookies ou tecnologia semelhante
              </p>
              <p>
                Você pode configurar manualmente seu navegador para notificá-lo quando receber um cookie. Isso permite
                que você decida se deseja aceitá-lo ou não. Como alternativa, você pode optar por desativar todos os
                cookies nas configurações do seu navegador. No entanto, alguns dos serviços e recursos oferecidos por
                meio de nosso site podem não funcionar corretamente se os cookies estiverem desativados.
              </p>
              <p>Os cookies podem ser cookies próprios ou de terceiros.</p>
              <p>Cookies primários - cookies que o site que você está visitando armazena em seu computador.</p>
              <p>
                Cookies de terceiros - cookies armazenados no seu computador através do site, mas por terceiros, como o
                Google.
              </p>
              <h4>Usamos os seguintes cookies em nosso site:</h4>
              <h3>Cookies estritamente necessários/sessão</h3>
              <p>
                Esses cookies são essenciais para permitir que você navegue em nosso site e use seus recursos. Sem esses
                cookies, os serviços solicitados não podem ser fornecidos. Eles são excluídos quando você fecha o
                navegador.
              </p>
              <h3>Cookies de desempenho</h3>
              <p>
                Esses cookies coletam dados anônimos sobre como os visitantes usam nosso site. Eles nos permitem
                reconhecer e contar o número de visitantes e ver como os visitantes se movem em nosso site quando o
                estão usando e as regiões aproximadas de onde estão visitando.
              </p>
              <h3>Cookies de funcionalidade</h3>
              <p>
                Esses cookies permitem que o site se lembre das escolhas que você faz (como seu nome de usuário, idioma
                ou a região em que você está) e fornece recursos aprimorados e mais pessoais. Esses cookies também podem
                ser usados para lembrar as alterações feitas no tamanho do texto, fontes e outras partes das páginas da
                web que você pode personalizar. As informações que esses cookies coletam podem ser anônimas e não podem
                rastrear sua atividade de navegação em outros sites.
              </p>
              <h3>Cookies de análise, publicidade e mídia social</h3>
              <p>
                O uso de cookies permite que nós e nossos anunciantes possamos fornecer informações mais relevantes para
                você e seus interesses e eles também podem conectá-lo a redes de mídia social. Esses são cookies
                persistentes que serão mantidos em seu dispositivo até sua expiração ou exclusão manual anterior.
              </p>
              <h3>Consentimento e desativação de cookies</h3>
              <p>
                Quando você chegar ao nosso site, uma mensagem pop-up será exibida notificando-o de que nosso site usa
                cookies. Ao usar nosso site, você concorda com o uso de cookies. Se você, ou outro usuário do seu
                dispositivo, deseja retirar o seu consentimento a qualquer momento, você pode fazê-lo alterando as
                configurações do seu navegador, caso contrário, presumiremos que você está feliz em receber cookies do
                nosso site.
              </p>
              <h2>14. Contate-nos</h2>
              <p>
                Após a leitura desta Política de Privacidade, caso tenha quaisquer dúvidas, reclamações, ou quiser
                exercer seus direitos relacionados aos seus dados pessoais, entre em contato conosco através de nosso
                canal de atendimento disponível em nosso website ou pelo nosso e-mail: privacidade@luxtak.com
              </p>
              <h2>15. Mudanças nesta Política de Privacidade</h2>
              <p>
                Podemos alterar a Política de Privacidade de tempos em tempos. Iremos informá-lo publicando a Política
                de Privacidade revisada no site. Por favor, verifique nossa Política de Privacidade periodicamente para
                alterações. Publicaremos a data da última atualização da Política de Privacidade na parte inferior da
                Política de Privacidade. As alterações entrarão em vigor na data da "Última atualização" mostrada na
                Política de Privacidade revisada. Ao continuar a usar o site ou nosso meio de pagamento, você concorda
                com nossa Política de Privacidade.
              </p>
            </div>
          ) : (
            <div>
              <h1>{t("privacy.policy")}</h1>
              <p>{t("strategy.brief")}</p>
              <p>{t("policy.purpose")}</p>
              <p>{t("note")}</p>
              <h2>{t("we.collect")}</h2>
              <p>{t("collet.situation")}</p>
              <ul>
                <li>{t("open.luxtak.account")}</li>
                <li>{t("use.our.website")}</li>
                <li>{t("close.the.deal")}</li>
                <li>{t("third.party.use")}</li>
                <li>{t("communicate")}</li>
              </ul>
              <h2>{t("how.use.your.info")}</h2>
              <p>{t("use.your.data.reasons")}</p>
              <ul>
                <li>{t("provide.luxtak.service")}</li>
                <li>{t("fraud.prevention")}</li>
                <li>{t("fulfill.our.law")}</li>
                <li>{t("other.business.needs")}</li>
              </ul>
              <h2>{t("how.spread.your.message")}</h2>
              <p>{t("privacy.protection")}</p>
              <ul>
                <li>{t("launch.a.transaction")}</li>
                <li>{t("transaction.processing")}</li>
                <li>{t("employing.third.purpose")}</li>
                <li>{t("law.required")}</li>
                <li>{t("law.required")}</li>
              </ul>
              <h2>{t("how.protect.your.information")}</h2>
              <p>{t("ssl.technology")}</p>
              <p>{t("view.https")}</p>
              <h2>{t("your.information.choices")}</h2>
              <p>{t("you.have.right")}</p>
              <ul>
                <li>{t("modify.your.message")}</li>
                <li>{t("delete.your.information")}</li>
              </ul>
              <p>{t("contact.us.delete.info")}</p>
              <h2>{t("luxtak.user.information")}</h2>
              <p>{t("not.provide.your.private_info")}</p>
              <h2>{t("cookies")}</h2>
              <p>{t("cookies.introduction")}</p>
              <p>{t("third.cookies")}</p>
              <p>{t("storage.cookies")}</p>
              <p>{t("from.third.cookies")}</p>
              <p>{t("user.Following.cookies")}</p>
              <h2>{t("strictly.session")}</h2>
              <p>{t("cookies.effect")}</p>
              <h2>{t("cookies.performance")}</h2>
              <p>{t("cookies.specific.purpose")}</p>
              <h2>{t("functional.cookies")}</h2>
              <p>{t("cookie.recorded.information")}</p>
              <h2>{t("analytical.cookies")}</h2>
              <p>{t("cookies.benefit")}</p>
              <h2>{t("consent2exclusion.cookies")}</h2>
              <p>{t("agree.cookies")}</p>
              <h2>{t("contact")}</h2>
              <p>{t("have.questions.ask.me")}</p>
              <h2>{t("change.privacy.policy")}</h2>
              <p>{t("change.privacy.policy.anytime")}</p>
              <p>{t("last.updated")}</p>
            </div>
          )
        ) : region === "BRA" ? (
          <div>
            <h1>Termos de serviço</h1>
            <p>
              A Luxtak desenvolveu este serviço de pagamento (o "Serviço") para tornar mais fácil para os comerciantes
              aceitarem uma grande variedade de métodos de pagamento locais preferenciais em muitos países. Ao usar o
              Serviço, você concorda com nossos termos de uso conforme estabelecido abaixo.
            </p>
            <p>
              Este Termo deﬁne as condições aplicáveis aos serviços prestados pela LUXTAK como “eFX” – serviços de
              pagamento ou transferência internacional, em conformidade com a Circular 3.691/2013 e demais normas
              aplicáveis sobre os serviços desenvolvidos pela LUXTAK.
            </p>
            <h2>1. Adesão ao serviço</h2>
            <p>
              1.1 A LUXTAK presta serviços de pagamento ou transferência internacional, por meio de operação de câmbio
              realizada por instituição autorizada a operar no mercado de câmbio, para aquisição de bens e serviços no
              País ou no Exterior (“Efx”), nos termos da resolução 3.691/2013 e demais normas aplicáveis aos serviços
              prestados pela LUXTAK, incluindo as disposições do Banco Central do Brasil (“BACEN”).
            </p>
            <p>
              1.1.2 O Usuário declara que foi informado de forma prévia, clara e tempestiva sobre as seguintes
              informações: a responsabilidade da LUXTAK quanto ao serviço; a natureza e as condições do serviço
              prestado; e as condições especíﬁcas relacionadas aos seus direitos de acordo com o instrumento de
              pagamento utilizado para a entrega dos reais m LUXTAK.
            </p>
            <p>
              1.1.3 Para a prestação dos serviços contratados, o Usuário desde já autoriza a LUXTAK a coletar, tratar e
              compartilhar os seus dados pessoais, ﬁnanceiros e cadastrais com outras empresas e instituições
              ﬁnanceiras.
            </p>
            <p>
              1.1.4 A LUXTAK disponibilizará ao Usuário o demonstrativo da operação, o qual constará a discriminação da
              operação, incluindo sua data, o valor em moeda nacional, o nome das partes envolvidas, eventuais tarifas
              cobradas em razão da operação, além de subtotais, quando aplicável, nos termos da regulamentação vigente
              aplicável.
            </p>
            <p>
              1.1.5 O Usuário declara que teve acesso prévio e tempestivo a este Termo e que concorda com todas as
              regras aqui estabelecidas e aplicáveis aos Serviços LUXTAK.
            </p>
            <p>
              1.1.6 A adesão do Usuário ao presente Termo se dá automaticamente no momento do pagamento em reais através
              dos meios de pagamento disponibilizados pelo LUXTAK.
            </p>
            <h3>1.2 Operação de câmbio</h3>
            <p>
              1.2.1 O Usuário declara ciência que no valor total da compra estará incluído o valor da taxa de conversão
              entre reais e dólar norte-americano na data do respectivo pagamento, estando, todavia, sujeita a variação
              cambial da taxa de conversão, conforme o caso. O valor das operações transacionadas nos websites de
              compras internacionais em moeda distinta do dólar norte-americano será primeiramente convertido da moeda
              aplicável na compra (por ex. EURO) para dólar norte-americano, conforme os sistemas e critérios utilizados
              pela LUXTAK e, posteriormente, será convertido em reais com a taxa de conversão da data da autorização da
              operação.
            </p>
            <p>
              1.2.1.1 O Usuário declara ciência e concordância que a efetivação da operação de câmbio poderá acontecer
              em data posterior a data da autorização da operação por ele realizada.
            </p>
            <p>
              1.2.2 O Usuário declara que tem plena ciência e conhecimento de que as taxas de conversão praticadas de
              acordo com este Termo poderão sofrer variações de acordo com o mercado de câmbio brasileiro.
            </p>
            <p>
              1.2.3 A operação de câmbio realizada pelo LUXTAK está sujeita a limites e condições impostas pela
              regulamentação aplicável m operação.
            </p>
            <p>
              1.2.4 Ao efetuar o pagamento em moeda nacional pela operação de câmbio realizada pela LUXTAK, através dos
              meios de pagamento por ela aceitos, o Usuário aceita esse Termo e, automaticamente, concorda com as taxas
              de câmbio contratadas pela LUXTAK junto m instituição ﬁnanceira.
            </p>
            <h2>2. Nosso software</h2>
            <p>
              Fornecemos a API Luxtak e outro software para permitir que você use o Serviço. Nós nos reservamos o
              direito de exigir que você instale ou atualize todas e quaisquer atualizações de software para continuar
              usando o Serviço. Nosso serviço também inclui software para ajudá-lo a gerenciar cobranças recorrentes e
              de cobrança de assinatura de seus produtos e serviços. É sua responsabilidade obter o consentimento dos
              clientes para serem cobrados de forma recorrente, em conformidade com os requisitos legais aplicáveis e as
              regras de pagamento da Rede de Cartões.
            </p>
            <h2>3. Autorização para movimentação de fundos</h2>
            <p>
              Ao aceitar este contrato, você nos autoriza a reter, receber e desembolsar fundos em seu nome quando tais
              fundos de suas transações de pagamento forem liquidados com os parceiros de pagamento. Você também
              autoriza o Luxtak a manter fundos de liquidação em uma conta de depósito pendente do desembolso dos fundos
              para você de acordo com os termos deste contrato. Você concorda que não tem direito a quaisquer juros ou
              outra compensação associada aos fundos de liquidação mantidos na conta de depósito pendente de liquidação
              em sua conta de depósito designada, que não tem direito de direcionar essa conta de depósito e que não
              pode atribuir quaisquer juros na conta de depósito. Exibiremos a liquidação antecipada no painel do
              administrador que recebemos ou estamos conﬁgurados para receber em seu nome. Estas informações de
              liquidação não constituem um depósito ou outra obrigação da Luxtak para com você. Essas informações de
              liquidação reﬂetidas no painel de gerenciamento do Luxtak são apenas para ﬁns de relatório e informativos,
              e você não tem direito a, e não tem propriedade ou outros direitos sobre fundos de liquidação, até que
              tais fundos sejam creditados em sua conta de depósito designada. Suas autorizações aqui estabelecidas
              permanecerão em pleno vigor e efeito até que sua conta Luxtak seja encerrada ou encerrada.
            </p>
            <h2>4. Métodos de pagamento</h2>
            <p>
              Oferecemos uma solução de pagamento global por meio de uma ampla gama de métodos de pagamento, como
              ewallets, débito direto, transferência bancária, pagamento em dinheiro, cartões pré-pagos.
            </p>
            <h2>5. Tributos</h2>
            <p>
              Será de responsabilidade do Usuário informar-se sobre os tributos incidentes sobre a importação de bens
              e/ou serviços cabendo, exclusivamente, a ele, se aplicável, declarar e recolher os tributos aplicáveis em
              razão da aquisição realizada pelo Website. Além disso, caberá ao Usuário, ainda, atender todas as
              obrigações e deveres exigidos pelos órgãos integrantes da Administração Pública direta e indireta. Não
              poderá o Usuário alegar desconhecimento sobre eventuais incidências de tributos sobre o serviço obtido
              junto ao website, nem tampouco responsabilizar a LUXTAK na hipótese de ser obrigado a recolher a tais
              órgãos estes tributos.
            </p>
            <h2>6. Segurança de seus dados</h2>
            <p>
              Você é totalmente responsável pela segurança dos dados em seu site ou de outra forma em sua posse. Você
              concorda em cumprir todas as leis e regras governamentais aplicáveis em relação m coleta, segurança e
              disseminação de qualquer informação pessoal, ﬁnanceira, de pagamento ou transação (deﬁnida como "Dados")
              em seu site. Você também é responsável por suas credenciais de login no painel de gerenciamento do Luxtak.
              Luxtak envidará seus melhores esforços para manter sua conta protegida contra logins fraudulentos.
            </p>
            <h2>7. Nossa Segurança</h2>
            <p>
              Luxtak é responsável por proteger a segurança dos Dados em nossa posse e manterá procedimentos
              administrativos, técnicos e físicos comercialmente razoáveis para proteger todas as informações pessoais
              sobre você e seus clientes que estão armazenadas em nossos servidores contra acesso não autorizado e perda
              ou modiﬁcação acidental. No entanto, não podemos garantir que terceiros não autorizados nunca serão
              capazes de anular essas medidas ou usar essas informações pessoais para ﬁns impróprios. Você reconhece que
              fornece essas informações pessoais sobre você e seus clientes por sua própria conta e risco. Recomendamos
              que você leia nossa Política de Privacidade, que o ajudará a entender como coletamos, usamos e protegemos
              as informações que você nos fornece.
            </p>
            <h2>8. Direito de auditoria</h2>
            <p>
              Se acreditarmos que ocorreu uma violação de segurança ou comprometimento de dados, a Luxtak pode exigir
              que você tenha um auditor terceirizado aprovado pela Luxtak para conduzir uma auditoria de segurança de
              seus sistemas e instalações e emitir um relatório a ser fornecido m Luxtak, bancos ﬁnanceiros e nossos
              sistemas de pagamento.
            </p>
            <h2>9. Sua privacidade</h2>
            <p>
              Sua privacidade e a proteção de seus dados são muito importantes para nós. Você reconhece que recebeu, leu
              na íntegra e concorda com os termos de nossa Política de Privacidade vinculados e incorporados a este
              Acordo por referência, que contém seu consentimento para nossa coleta, uso, retenção e divulgação de
              informações pessoais, bem como outros assuntos estabelecido nele e que explica como e para quais ﬁns
              coletamos, usamos, retemos, divulgamos e salvaguardamos as informações que você nos fornece. Você também
              reconhece que nós ou nossos parceiros de pagamento podemos ser obrigados a relatar o nome da sua empresa e
              o nome de seus principais ms autoridades governamentais, se for determinado por uma ordem judicial. As
              informações que você decidir enviar podem ser usadas para parceiros do sistema de pagamento apenas para
              fornecer mais opções de pagamento. O Luxtak nunca venderá nenhum de vocês ou seus dados de usuário a
              terceiros para ﬁns de marketing.
            </p>
            <h2>10. Privacidade de terceiros</h2>
            <p>
              Você declara ao Luxtak que está em conformidade com todas as leis de privacidade aplicáveis, obteve todos
              os direitos e consentimentos necessários nos termos da lei aplicável para divulgar ao Luxtak, ou que o
              Luxtak coletará, usar, reter e divulgar quaisquer Dados do Usuário que você nos fornecer ou nos autorize a
              cobrar. Entre você e a Luxtak, você é o único responsável por divulgar aos seus clientes que a Luxtak está
              fornecendo serviços de pagamento para você e obter seus dados sobre esses clientes.
            </p>
            <h2>11. Uso Restrito</h2>
            <p>
              Você deve obedecer a todas as leis, regras e regulamentos aplicáveis ao seu uso do Serviço (por exemplo,
              aqueles que regem os serviços ﬁnanceiros, proteção ao consumidor, concorrência desleal, antidiscriminação
              ou propaganda enganosa). Além de quaisquer outros requisitos ou restrições estabelecidos neste Acordo,
              você não deve: (i) utilizar as opções de pagamento como uma forma de adiantar dinheiro aos usuários por
              meio de métodos de pagamento, (ii) enviar quaisquer transações de pagamento para processamento que não
              tenham surgido desde a venda de bens ou serviços a um cliente comprador, ou aceitação de uma doação de
              caridade genuína, (iii) atuar como intermediário de pagamento ou agregador ou de outra forma revender
              nossos serviços em nome de terceiros, (iv) enviar o que você acredita para ser autorizações potencialmente
              fraudulentas ou transação de pagamento fraudulenta, ou (v) usar o Luxtak de uma maneira que nossos
              parceiros de pagamento acreditem que você está abusando dos serviços do Luxtak e violando as regras
              aplicáveis. Você também concorda em não, nem permitir que terceiros façam qualquer um dos seguintes: (i)
              acessar ou tentar acessar sistemas, programas ou dados da Luxtak que não estejam disponíveis para uso
              público; (ii) copiar, reproduzir, republicar, fazer upload, postar, transmitir, revender ou distribuir de
              qualquer forma o material do Luxtak; (iii) permitir que terceiros usem e se beneﬁciem do Serviço por meio
              de aluguel, arrendamento, compartilhamento de tempo, bureau de serviços ou outro acordo; (iv) transferir
              quaisquer direitos concedidos a você sob este Acordo; (v) contornar qualquer uma das limitações técnicas
              do Serviço, usar qualquer ferramenta para habilitar recursos ou funcionalidades que estão de outra forma
              desabilitados no Serviço, ou descompilar, desmontar ou fazer engenharia reversa do Serviço, exceto na
              medida em que tal restrição seja expressamente proibido por lei; (vi) realizar ou tentar realizar
              quaisquer ações que possam interferir com o funcionamento adequado do Serviço, impedir o acesso ou uso do
              Serviço por nossos outros usuários ou impor uma carga não razoável ou desproporcionalmente grande em nossa
              infraestrutura; ou (vii) de outra forma usar o Serviço, exceto conforme expressamente permitido nesta
              seção.
            </p>
            <h2>12. Suspeita de uso não autorizado ou ilegal</h2>
            <p>
              Nós nos reservamos o direito de não autorizar ou liquidar qualquer transação que você enviar que
              acreditemos estar em violação deste Acordo, qualquer outro acordo Luxtak, ou exponha você, outros usuários
              Luxtak, nossos processadores ou Luxtak a danos, incluindo, mas não se limitando a fraude e outros atos
              criminosos. Você está nos concedendo autorização para compartilhar informações com as autoridades
              policiais sobre você, suas transações ou sua conta de serviço Luxtak, caso elas o solicitem por meio de
              uma ordem judicial ou se for considerado ilegal pelo governo de Hong Kong.
            </p>
            <h2>13. Divulgações e Avisos</h2>
            <p>
              Você concorda que Luxtak pode fornecer divulgações e avisos sobre o Serviço para você postando tais
              divulgações e avisos em nosso site, enviando-os para o endereço de e-mail listado em sua conta Luxtak ou
              enviando-os para o endereço listado em sua Conta de Comerciante Luxtak. Você também concorda que as
              divulgações e notiﬁcações eletrônicas têm o mesmo signiﬁcado e efeito que se tivéssemos fornecido uma
              cópia impressa. Essas divulgações e avisos serão considerados recebidos por você dentro de 24 horas a
              partir do momento em que forem postados em nosso site ou enviados por e-mail a você, a menos que recebamos
              um aviso de que o e-mail não foi entregue. Se você receber informações sobre outras pessoas, incluindo
              titulares de cartão, por meio do uso do Serviço, deverá manter tais informações conﬁdenciais e usá-las
              apenas em conexão com o Serviço. Você não pode divulgar ou distribuir tais informações a terceiros ou usar
              quaisquer dessas informações para ﬁns de marketing, a menos que receba o consentimento expresso do usuário
              para fazê-lo. Você não pode divulgar informações de pagamento a terceiros, exceto em conexão com o
              processamento de um pagamento para seus usuários sob este Serviço.
            </p>
            <h2>14. Referências ao nosso relacionamento</h2>
            <p>
              Você concorda que, a partir do momento em que começa a processar o pagamento com Luxtak até o encerramento
              de sua conta conosco, podemos identiﬁcá-lo como um cliente de Luxtak. Nem você nem nós iremos sugerir
              qualquer patrocínio falso, endosso ou aﬁliação entre você e o Luxtak.
            </p>
            <h2>15. Isenção de responsabilidade</h2>
            <p>
              As informações contidas neste site são apenas para ﬁns de informação geral. As informações são fornecidas
              pela Luxtak e, embora nos esforcemos para mantê-las atualizadas e corretas, não fazemos representações ou
              garantias de qualquer tipo, expressas ou implícitas, sobre a integridade, precisão, conﬁabilidade,
              adequação ou disponibilidade em relação ao site ou as informações, produtos, serviços ou gráﬁcos
              relacionados contidos no site para qualquer ﬁnalidade. Qualquer conﬁança que você depositar em tais
              informações é, portanto, estritamente por sua própria conta e risco. Em nenhum caso seremos responsáveis
              por qualquer perda ou dano, incluindo, sem limitação, perda ou dano indireto ou consequencial, ou qualquer
              perda ou dano resultante da perda de dados ou lucros decorrentes de, ou em conexão com, o uso deste site .
              Através deste site, você pode acessar outros sites que não estão sob o controle da Luxtak. Não temos
              controle sobre a natureza, conteúdo e disponibilidade desses sites. A inclusão de quaisquer links não
              implica necessariamente uma recomendação ou endossa as opiniões expressas neles. Todos os esforços são
              feitos para manter o site funcionando sem problemas. No entanto, Luxtak não se responsabiliza por, e não
              será responsável por, o site estar temporariamente indisponível devido a problemas técnicos fora do nosso
              controle.
            </p>
            <h2>16. Alteração de Termo</h2>
            <p>
              A LUXTAK poderá, a qualquer tempo, alterar as condições estabelecidas neste Termo, mediante prévia
              notiﬁcação ao Usuário, por escrito, com no mínimo 7 dias de antecedência, a ﬁm de adaptá-lo as alterações
              legislativas e regulatórias aplicáveis aos serviços. Caso o Usuário não concorde com as alterações
              realizadas pela LUXTAK, poderá imediatamente solicitar a rescisão deste Termo.
            </p>
          </div>
        ) : (
          <div>
            <h1>{t("terms.title")}</h1>
            <p>{t("terms.text")}</p>
            <h2>{t("terms.our.service.title")}</h2>
            <p>{t("terms.our.service.text")}</p>
            <h2>{t("terms.our.software.title")}</h2>
            <p>{t("terms.our.software.text")}</p>
            <h2>{t("terms.authorization.title")}</h2>
            <p>{t("terms.authorization.text")}</p>
            <h2>{t("terms.payment.methods.title")}</h2>
            <p>{t("terms.payment.methods.text")}</p>
            <h2>{t("terms.taxes.title")}</h2>
            <p>{t("terms.taxes.text")}</p>
            <h2>{t("terms.data.security.title")}</h2>
            <p>{t("terms.data.security.text")}</p>
            <h2>{t("terms.our.security.title")}</h2>
            <p>{t("terms.our.security.text")}</p>
            <h2>{t("terms.audit.title")}</h2>
            <p>{t("terms.audit.text")}</p>
            <h2>{t("terms.privacy.title")}</h2>
            <p>{t("terms.privacy.text")}</p>
            <h2>{t("terms.other.privacy.title")}</h2>
            <p>{t("terms.other.privacy.text")}</p>
            <h2>{t("terms.restricted.title")}</h2>
            <p>{t("terms.restricted.text")}</p>
            <h2>{t("terms.illegal.use.title")}</h2>
            <p>{t("terms.illegal.use.text")}</p>
            <h2>{t("terms.disclosures.title")}</h2>
            <p>{t("terms.disclosures.text")}</p>
            <h2>{t("terms.references.title")}</h2>
            <p>{t("terms.references.text")}</p>
            <h2>{t("terms.disclaimer.title")}</h2>
            <p>{t("terms.disclaimer.text")}</p>
            {provider === "OpenPay" && region === "PER" && <h2>{t("terms.opanpay")}</h2>}
          </div>
        )}
      </div>
    </div>
  );
}

export default PrivacyPolicy;
