import React from "react";
import "./CopyButton.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import CopyIcon from "../../img/copy.svg";
import Button from "@material-ui/core/Button";

function CopyButton({ text, buttonText, minWidth = 150, height = 45, onlyIcon = false }) {
  const { t } = useTranslation();
  const notify = () => {
    toast.success(t("copied"), {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  return (
    <div className="copyButton">
      <CopyToClipboard text={text} onCopy={() => {}}>
        <Button
          className="copyButton__button"
          onClick={notify}
          style={{ minWidth: minWidth, height: height, border: onlyIcon ? "none" : "2px solid var(--luxtak--color)" }}
        >
          {!onlyIcon && (buttonText || t("copy"))}
          <img
            src={CopyIcon}
            alt=""
            style={{
              marginLeft: onlyIcon ? "0px" : "10px",
            }}
          />
        </Button>
      </CopyToClipboard>
    </div>
  );
}

export default CopyButton;
