import React from "react";
import { FormatMoney } from "../../utils/formats";
import { useTranslation } from "react-i18next";
import InfoPromp from "./InfoPromp";

const formatExt = (ext) => {
  return ext ? ` (${ext.replace(/[,]/g, "")})` : "";
};

const formatDefaultFee = (feeName, ext) => {
  feeName = feeName?.lastIndexOf?.("-") !== -1 ? feeName?.slice?.(0, feeName.lastIndexOf("-")) : feeName;
  return feeName + formatExt(ext);
};

function Fees({ fees = [], styles: { container = "", content = "" }, region }) {
  const { t } = useTranslation();
  const formattedFees = [];
  const bankFees = {
    fee_type: 102,
    fee_amount: 0,
  };
  fees.forEach((item) => {
    if (item.fee_type === 102) {
      bankFees.fee_amount = bankFees.fee_amount + item.fee_amount;
    } else {
      formattedFees.push(item);
    }
  });
  bankFees.fee_amount !== 0 && formattedFees.push(bankFees);

  return (
    <>
      {formattedFees?.map?.((item) => (
        <div className={container}>
          <p className={content.text || ""} style={{ display: "flex", alignItems: "center" }}>
            {item.fee_type === 100
              ? formatDefaultFee(item.fee_name, item.ext)
              : t(`fee_${item.fee_type}`) + formatExt(item.ext)}
            {region === "ARG" && t("notice_" + item.fee_type) && (
              <InfoPromp
                promp={<p style={{ fontSize: "12px" }}>{t("notice_" + item.fee_type, { key: formatExt(item.ext) })}</p>}
                position="bottom-right"
                width={250}
                iconSize={20}
                style={{ marginLeft: "5px" }}
              />
            )}
          </p>
          <p className={content.value || ""}>
            <FormatMoney type="fee" data={item.fee_amount} />
          </p>
        </div>
      ))}
    </>
  );
}

export default Fees;
