import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Correct from "../../img/correct.jpg";
import { checkLastName } from "../../utils/validations";
import { useSelector } from "react-redux";
import InfoPromp from "../comp/InfoPromp";
import CardInfo from "../../img/cardLname_info.png";

function LastName({ form, setForm, credircard, placeholder, setFocusOnInput = () => {} }) {
  const { t } = useTranslation();
  const [tradeInfo] = useSelector((state) => [state.tradeInfo]);
  useEffect(() => {
    if (!form.lastName.checked && !credircard) {
      let name = tradeInfo?.user?.username ? tradeInfo?.user?.username : localStorage?.getItem("__frm_name");
      if (name) {
        const nameArr = String(name).split(" ");
        if (nameArr.length > 1) {
          name = nameArr.pop().trim();
          setForm((prev) => {
            return {
              ...prev,
              lastName: { ...prev.lastName, value: name },
            };
          });
          checkLastName({ form: form, setForm: setForm, data: name });
        }
      }
    }
  }, []);
  return (
    <>
      <TextField
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        placeholder={placeholder || ""}
        inputProps={{ autocomplete: "new-password", "ga-data": "338" }}
        size="small"
        error={!form.lastName.valid}
        helperText={!form.lastName.valid && t("invalid_name")}
        className="homeMobile__filling__content__input"
        label={t("last_name")}
        variant="outlined"
        value={form.lastName.value}
        onChange={(event) => {
          var format = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`·～！¥（）—】【」「、｜；：’‘“”？》。《，]+/g;
          if (credircard) {
            format = /[^a-zA-z ]/g;
          }
          var value = event.target.value;
          value = value.replace(format, "");
          if (value?.length > form.lastName.max) {
            value = value.slice(0, form.lastName.max);
          }
          setForm((prev) => {
            return {
              ...prev,
              lastName: { ...prev.lastName, value: value },
            };
          });
        }}
        onBlur={() => {
          checkLastName({ form: form, setForm: setForm });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={Correct}
                alt=""
                style={{
                  display: form.lastName.valid && form.lastName.checked ? "block" : "none",
                }}
              />
              {credircard && <InfoPromp promp={<img src={CardInfo} alt="" />} />}
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default LastName;
