/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import { isProduction } from "../envs/env";
import req from "./axios";

export const getTongdunFingerPrint = (callback = () => {}) => {
  try {
    document.cookie = "TDpx=1";
    window._fmOpt = {
      partner: "luxtak",
      appName: "luxtak_web",
      token: "luxtak" + "-" + new Date().getTime() + "-" + Math.random().toString(16).substr(2),
      fpHost: "https://usfp.tongdun.net",
      fmb: true,
      success: function (data) {
        // 在成功完成采集后，success回调中可以获取到black_box
        //这里可以设置客户需求的操作，传输black_box等操作
        //获取设备指纹黑盒数据，并提交到业务服务器
        //console.log("black_box: ", data);
        window.black_box_tongdun = data;
        callback();
      },
    };
    var fm = document.createElement("script");
    fm.type = "text/javascript";
    fm.async = true;
    fm.src = "https://static.tongdun.net/us/fm.js?ver=0.1&t=" + (new Date().getTime() / 3600000).toFixed(0);
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(fm, s);
  } catch (error) {}
};

const getForterFingerPrint = (callback = () => {}, siteID) => {
  try {
    document.addEventListener("ftr:tokenReady", function (evt) {
      window.black_box_forter = evt.detail;
      // console.log("forter_token >>>: ", evt.detail);
      callback();
      // Retrieve the token to be sent to your back-end
    });

    (function () {
      var eu = "22g4zl{5jf5fjtlv1forxgiurqw1qhw2vwdwxv";
      var siteId = siteID;

      function t(t, e) {
        for (var n = t.split(""), r = 0; r < n.length; ++r) n[r] = String.fromCharCode(n[r].charCodeAt(0) + e);
        return n.join("");
      }

      function e(e) {
        return t(e, -_).replace(/%SN%/g, siteId);
      }

      function n() {
        var t = "no" + "op" + "fn",
          e = "g" + "a",
          n = "n" + "ame";
        return window[e] && window[e][n] === t;
      }

      function r() {
        return !(!navigator.brave || "function" != typeof navigator.brave.isBrave);
      }

      function o() {
        return document.currentScript && document.currentScript.src;
      }

      function i(t) {
        try {
          (F.ex = t),
            n() && -1 === F.ex.indexOf(E.uB) && (F.ex += E.uB),
            r() && -1 === F.ex.indexOf(E.uBr) && (F.ex += E.uBr),
            o() && -1 === F.ex.indexOf(E.nIL) && (F.ex += E.nIL),
            window.ftr__snp_cwc || (F.ex += E.s),
            q(F);
        } catch (t) {}
      }

      function a(t, e) {
        function n(o) {
          try {
            o.blockedURI === t && (e(), document.removeEventListener(r, n));
          } catch (t) {
            document.removeEventListener(r, n);
          }
        }
        var r = "securitypolicyviolation";
        document.addEventListener(r, n),
          setTimeout(function () {
            document.removeEventListener(r, n);
          }, 2 * 60 * 1e3);
      }

      function c(t, e, n, r) {
        var o = !1;
        (t = "https://" + t),
          a(t, function () {
            r(!0), (o = !0);
          });
        var i = document.createElement("script");
        (i.onerror = function () {
          if (!o)
            try {
              r(!1), (o = !0);
            } catch (t) {}
        }),
          (i.onload = n),
          (i.type = "text/javascript"),
          (i.id = "ftr__script"),
          (i.async = !0),
          (i.src = t);
        var c = document.getElementsByTagName("script")[0];
        c.parentNode.insertBefore(i, c);
      }

      function u(t, e, n) {
        var r = !1,
          o = new XMLHttpRequest();
        if (
          (a(t, function () {
            n(new Error("CSP Violation"), !0), (r = !0);
          }),
          "withCredentials" in o)
        )
          o.open("GET", t, !0);
        else {
          if ("undefined" == typeof XDomainRequest) return;
          // eslint-disable-next-line no-undef
          (o = new XDomainRequest()), o.open("GET", t);
        }
        (o.onload = function () {
          "function" == typeof e && e(o);
        }),
          (o.onerror = function (t) {
            if ("function" == typeof n && !r)
              try {
                n(t, !1), (r = !0);
              } catch (t) {}
          }),
          (o.onprogress = function () {}),
          (o.ontimeout = function () {
            "function" == typeof n && n("tim" + "eo" + "ut", !1);
          }),
          setTimeout(function () {
            o.send();
          }, 0);
      }

      function d() {
        u(
          y,
          function (n) {
            try {
              var r = n.getAllResponseHeaders().toLowerCase();
              if (r.indexOf(x.toLowerCase()) < 0) return;
              var o = n.getResponseHeader(x),
                i = t(o, -_ - 1);
              if (i) {
                var a = i.split(":");
                if (a && 3 === a.length) {
                  var c = a[0],
                    u = a[1],
                    d = a[2];
                  switch (u) {
                    case "none":
                      A = c + T;
                      break;
                    case "res":
                      A = c + T + d;
                      break;
                    case "enc":
                      A = c + e("1forxgiurqw1qhw2vq2(VQ(2vfulsw1mv");
                      break;
                    case "enc-res":
                      for (var s = "", v = 0, l = 0; v < 20; ++v)
                        s += v % 3 > 0 && l < 12 ? siteId.charAt(l++) : F.id.charAt(v);
                      var m = d.split(".");
                      if (m.length > 1) {
                        var h = m[0],
                          w = m[1];
                        A = c + T + h + "." + s + "." + w;
                        break;
                      }
                      if (r.indexOf(S.toLowerCase()) >= 0) {
                        var p = n.getResponseHeader(S),
                          g = t(p, -_ - 1);
                        window.ftr__altd = g;
                      }
                      j(E.dUAL), setTimeout(f, U, E.dUAL);
                  }
                }
              }
            } catch (t) {}
          },
          function (t, e) {
            i(e ? E.uAS + E.cP : E.uAS);
          }
        );
      }

      function f(t) {
        try {
          var e = t === E.uDF ? L : A;
          if (!e) return;
          c(
            e,
            void 0,
            function () {
              try {
                Q(), i(t + E.uS);
              } catch (t) {}
            },
            function (e) {
              try {
                Q(), (F.td = 1 * new Date() - F.ts), i(e ? t + E.uF + E.cP : t + E.uF), t === E.uDF && d();
              } catch (t) {
                i(E.eUoe);
              }
            }
          );
        } catch (e) {
          i(t + E.eTlu);
        }
      }
      var s = "fort",
        v = "erTo",
        l = "ken",
        m = s + v + l,
        h = 10,
        w = {
          write: function (t, e, n, r) {
            void 0 === r && (r = !0);
            var o, i;
            if (
              (n
                ? ((o = new Date()),
                  o.setTime(o.getTime() + 24 * n * 60 * 60 * 1e3),
                  (i = "; expires=" + o.toGMTString()))
                : (i = ""),
              !r)
            )
              return void (document.cookie = escape(t) + "=" + escape(e) + i + "; path=/");
            for (var a = 1, c = document.domain.split("."), u = h, d = !0; d && c.length >= a && u > 0; ) {
              var f = c.slice(-a).join(".");
              document.cookie = escape(t) + "=" + escape(e) + i + "; path=/; domain=" + f;
              var s = w.read(t);
              (null != s && s == e) ||
                ((f = "." + f), (document.cookie = escape(t) + "=" + escape(e) + i + "; path=/; domain=" + f)),
                (d = -1 === document.cookie.indexOf(t + "=" + e)),
                a++,
                u--;
            }
          },
          read: function (t) {
            var e = null;
            try {
              for (var n = escape(t) + "=", r = document.cookie.split(";"), o = 32, i = 0; i < r.length; i++) {
                for (var a = r[i]; a.charCodeAt(0) === o; ) a = a.substring(1, a.length);
                0 === a.indexOf(n) && (e = unescape(a.substring(n.length, a.length)));
              }
            } finally {
              return e;
            }
          },
        },
        p = "14";
      p += "ck";
      var g = function (t) {
          if (document.head) {
            var e = (function () {
              var e = document.createElement("link");
              return (
                e.setAttribute("rel", "pre" + "con" + "nect"),
                e.setAttribute("cros" + "sori" + "gin", "anonymous"),
                (e.onload = function () {
                  document.head.removeChild(e);
                }),
                (e.onerror = function (t) {
                  document.head.removeChild(e);
                }),
                e.setAttribute("href", t),
                document.head.appendChild(e),
                e
              );
            })();
            setTimeout(function () {
              document.head.removeChild(e);
            }, 3e3);
          }
        },
        _ = 3,
        y = e(eu || "22g4zl{5jf5fjtlv1forxgiurqw1qhw2vwdwxv"),
        T = t("1forxgiurqw1qhw2", -_),
        x = t("[0Uhtxhvw0LG", -_),
        S = t("[0Fruuhodwlrq0LG", -_),
        A,
        L = e("(VQ(1fgq71iruwhu1frp2vq2(VQ(2vfulsw1mv"),
        k = e("(VQ(1fgq71iruwhu1frp2vqV2(VQ(2vfulsw1mv"),
        U = 10;
      window.ftr__startScriptLoad = 1 * new Date();
      var D = function (t) {
          var e = "ft" + "r:tok" + "enR" + "eady";
          window.ftr__tt && clearTimeout(window.ftr__tt),
            (window.ftr__tt = setTimeout(function () {
              try {
                delete window.ftr__tt, (t += "_tt");
                var n = document.createEvent("Event");
                n.initEvent(e, !1, !1), (n.detail = t), document.dispatchEvent(n);
              } catch (t) {}
            }, 1e3));
        },
        q = function (t) {
          var e = function (t) {
              return t || "";
            },
            n = e(t.id) + "_" + e(t.ts) + "_" + e(t.td) + "_" + e(t.ex) + "_" + e(p);
          w.write(m, n, 1825, !0), D(n), (window.ftr__gt = n);
        },
        V = function () {
          var t = w.read(m) || "",
            e = t.split("_"),
            n = function (t) {
              return e[t] || void 0;
            };
          return {
            id: n(0),
            ts: n(1),
            td: n(2),
            ex: n(3),
            vr: n(4),
          };
        },
        b = (function () {
          for (var t = {}, e = "fgu", n = [], r = 0; r < 256; r++) n[r] = (r < 16 ? "0" : "") + r.toString(16);
          var o = function (t, e, r, o, i) {
              var a = i ? "-" : "";
              return (
                n[255 & t] +
                n[(t >> 8) & 255] +
                n[(t >> 16) & 255] +
                n[(t >> 24) & 255] +
                a +
                n[255 & e] +
                n[(e >> 8) & 255] +
                a +
                n[((e >> 16) & 15) | 64] +
                n[(e >> 24) & 255] +
                a +
                n[(63 & r) | 128] +
                n[(r >> 8) & 255] +
                a +
                n[(r >> 16) & 255] +
                n[(r >> 24) & 255] +
                n[255 & o] +
                n[(o >> 8) & 255] +
                n[(o >> 16) & 255] +
                n[(o >> 24) & 255]
              );
            },
            i = function () {
              if (window.Uint32Array && window.crypto && window.crypto.getRandomValues) {
                var t = new window.Uint32Array(4);
                return (
                  window.crypto.getRandomValues(t),
                  {
                    d0: t[0],
                    d1: t[1],
                    d2: t[2],
                    d3: t[3],
                  }
                );
              }
              return {
                d0: (4294967296 * Math.random()) >>> 0,
                d1: (4294967296 * Math.random()) >>> 0,
                d2: (4294967296 * Math.random()) >>> 0,
                d3: (4294967296 * Math.random()) >>> 0,
              };
            },
            a = function () {
              var t = "",
                e = function (t, e) {
                  for (var n = "", r = t; r > 0; --r) n += e.charAt((1e3 * Math.random()) % e.length);
                  return n;
                };
              return (t += e(2, "0123456789")), (t += e(1, "123456789")), (t += e(8, "0123456789"));
            };
          return (
            (t.safeGenerateNoDash = function () {
              try {
                var t = i();
                return o(t.d0, t.d1, t.d2, t.d3, !1);
              } catch (t) {
                try {
                  return e + a();
                } catch (t) {}
              }
            }),
            (t.isValidNumericalToken = function (t) {
              return (
                t &&
                t.toString().length <= 11 &&
                t.length >= 9 &&
                parseInt(t, 10).toString().length <= 11 &&
                parseInt(t, 10).toString().length >= 9
              );
            }),
            (t.isValidUUIDToken = function (t) {
              return t && 32 === t.toString().length && /^[a-z0-9]+$/.test(t);
            }),
            (t.isValidFGUToken = function (t) {
              return 0 == t.indexOf(e) && t.length >= 12;
            }),
            t
          );
        })(),
        E = {
          uDF: "UDF",
          dUAL: "dUAL",
          uAS: "UAS",
          mLd: "1",
          eTlu: "2",
          eUoe: "3",
          uS: "4",
          uF: "9",
          tmos: ["T5", "T10", "T15", "T30", "T60"],
          tmosSecs: [5, 10, 15, 30, 60],
          bIR: "43",
          uB: "u",
          uBr: "b",
          cP: "c",
          nIL: "i",
          s: "s",
        },
        C = function (t, e) {
          for (var n = E.tmos, r = 0; r < n.length; r++) if (t + n[r] === e) return !0;
          return !1;
        };
      try {
        var F = V();
        try {
          F.id && (b.isValidNumericalToken(F.id) || b.isValidUUIDToken(F.id) || b.isValidFGUToken(F.id))
            ? (window.ftr__ncd = !1)
            : ((F.id = b.safeGenerateNoDash()), (window.ftr__ncd = !0)),
            (F.ts = window.ftr__startScriptLoad),
            q(F),
            (window.ftr__snp_cwc = !!w.read(m)),
            window.ftr__snp_cwc || (L = k);
          for (
            var I = "for" + "ter" + ".co" + "m",
              R = "ht" + "tps://c" + "dn9." + I,
              B = "ht" + "tps://" + F.id + "-" + siteId + ".cd" + "n." + I,
              G = "http" + "s://cd" + "n3." + I,
              O = [R, B, G],
              M = 0;
            M < O.length;
            M++
          )
            g(O[M]);
          var N = new Array(E.tmosSecs.length),
            j = function (t) {
              for (var e = 0; e < E.tmosSecs.length; e++) N[e] = setTimeout(i, 1e3 * E.tmosSecs[e], t + E.tmos[e]);
            },
            Q = function () {
              for (var t = 0; t < E.tmosSecs.length; t++) clearTimeout(N[t]);
            };
          // eslint-disable-next-line no-undef
          C(E.uDF, F.ex) ? loadAlternate() : (j(E.uDF), setTimeout(f, U, E.uDF));
        } catch (t) {
          i(E.mLd);
        }
      } catch (t) {}
    })();
  } catch (error) {}
};

export const getFingerPrint = (callback, prepayId) => {
  if (!isProduction()) {
    callback();
    return;
  }
  // const forterPromise = new Promise((resolve, reject) => {
  //   req
  //     .post(`/api/trade/rmsp?prepay_id=${prepayId}`)
  //     .then((res) => {
  //       const provider = res.data?.data?.provider;
  //       const siteId = res.data?.data?.site_id;
  //       if (provider === "Forter" && siteId) {
  //         getForterFingerPrint(resolve, siteId);
  //       }
  //     })
  //     .catch(() => {
  //       console.log("/api/trade/rmsp error!");
  //     });
  // });

  // const tongdunPromise = new Promise((resolve, reject) => {
  //   getTongdunFingerPrint(resolve);
  // });

  // Promise.race([forterPromise, tongdunPromise]).then(() => {
  //   callback();
  // });

  // forterPromise.then(() => {
  //   callback();
  // });
  callback(); // 2021-09-17 fingerprint 由于forter的问题，暂时不用
};
