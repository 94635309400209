import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setAlert } from "../../redux/actions";
import Warning from "../../img/warning.png";
import { makeStyles } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";

const useStyles = makeStyles({
  root: {
    "& .MuiDialog-paper": {
      alignItems: "center",
      padding: "20px",
      maxWidth: "400px",
      "& .MuiDialogContent-root": {
        textAlign: "center",
        padding: "10px",
        "& .MuiDialogContentText-root": {
          fontFamily: "Montserrat-Bold",
          fontSize: "15px",
          color: "rgba(0, 0, 0, 0.84)",
          lineHeight: "1.4",
        },
      },
      "& .MuiButton-root": {
        border: "1px solid var(--luxtak--color)",
        borderRadius: "20px",
        color: "var(--luxtak--color)",
        minWidth: "100px",
        textTransform: "none",
      },
    },
  },
});

function AlertDialog() {
  const { t } = useTranslation();
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();

  // console.log("alert: ", alert);

  const handleClose = (event) => {
    event.preventDefault();
    dispatch(setAlert({ state: false }));
    // if (alert?.callback) alert.callback?.();
  };

  const classes = useStyles();

  return (
    <Dialog
      open={alert?.state}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
      disableRestoreFocus
      TransitionComponent={Zoom}
    >
      {alert?.title && <DialogTitle id="alert-dialog-title">{alert.title}</DialogTitle>}
      <img src={Warning} alt="" style={{ padding: "10px" }} />
      {alert?.message && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{alert?.message}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("okay")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog;
