import req from "./axios";
import { secUrl, getGoogleRecaptchaId } from "../envs/env";
import store from "../redux/store";

export const tokenize = (prepayId, issuer, form, success = () => {}, fail = () => {}) => {
  const { tradeInfo } = store.getState();
  let postUrl = `/api/trade/gen-access-key?prepay_id=${prepayId}`;
  if (tradeInfo?.app_info?.global) postUrl += `&region=${tradeInfo?.app_info?.region}`;
  req
    .post(postUrl)
    .then((res) => {
      if (typeof res.data === "string") {
        req
          .post(`${secUrl}/api/tokenize?prepay_id=${prepayId}&access_key=${res.data}`, {
            card: {
              card_no: form.cardNumber.value.replace(/[^\d]/g, ""),
              issuer: issuer,
              cvv: form.cvc.value,
              valid_thru_year: form.expYear.value,
              valid_thru_month: form.expMonth.value,
              holder: {
                name: form.name.value,
              },
            },
          })
          .then((res) => {
            if (typeof res.data === "string") {
              success(res.data);
            } else {
              fail("/tokenize Error");
            }
          })
          .catch((err) => {
            fail();
          });
      } else {
        fail("/gen-access-key Error");
      }
    })
    .catch((err) => {
      fail(err);
    });
};

export const paypalTokenize = (method, channel, prepayId, success = () => {}, fail = () => {}) => {
  const { tradeInfo } = store.getState();
  let postUrl = `/api/trade/gen-access-key?prepay_id=${prepayId}&method=${method}&channel=${channel}`;
  if (tradeInfo?.app_info?.global) postUrl += `&region=${tradeInfo?.app_info?.region}`;
  req
    .post(postUrl)
    .then((res) => {
      if (typeof res.data === "string")
        return req.post(`${secUrl}/api/get-paypal-client-token?prepay_id=${prepayId}&access_key=${res.data}`);
      else throw new Error("/gen-access-key error!");
    })
    .then((res) => {
      success(res.data);
    })
    .catch((err) => {
      fail(err);
    });
};

export const authorize = (reqData, prepayId, success = () => {}, fail = () => {}) => {
  req
    .post(`/api/trade/user-authorization?prepay_id=${prepayId}`, reqData)
    .then((res) => {
      success(res);
    })
    .catch((err) => {
      fail(err);
    });
};

export const queryKey = (method, prepayId) => {
  const { tradeInfo } = store.getState();
  return new Promise((resolve, reject) => {
    let postUrl = `/api/trade/query-key?prepay_id=${prepayId}&method=${method}`;
    if (tradeInfo?.app_info?.global) postUrl += `&region=${tradeInfo?.app_info?.region}`;
    req
      .post(postUrl)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const googleTokenize = () => {
  return new Promise((resolve, reject) => {
    const grecaptcha = window.grecaptcha;
    try {
      const grecaptchaId = getGoogleRecaptchaId();
      grecaptcha.enterprise.ready(function () {
        try {
          grecaptcha.enterprise
            .execute(grecaptchaId, { action: "purchase" })
            .then(function (token) {
              resolve(token);
            })
            .catch((err) => {
              // console.log("1>>>>>>>>>>>>>>>>>>>>>", err);
              resolve("");
            });
        } catch (err) {
          // console.log("2>>>>>>>>>>>>>>>>>>>>>", err);
          resolve("");
        }
      });
    } catch (err) {
      // console.log("3>>>>>>>>>>>>>>>>>>>>>", err);
      resolve("");
    }
  });
};
