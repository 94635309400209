import React, { useEffect, useState } from "react";
import "./Result.scss";
import { connect } from "react-redux";
import TitleWithDetails from "../../comp/TitleWithDetails";
import QMark from "../../../img/问号.png";
import Chat from "../../../img/chat_green.png";
import PaymentCode from "../../comp/PaymentCode";
import Processing from "./Processing";
import Failed from "./Failed";
import Success from "./Success";
import Other from "./Other";
import { useTranslation } from "react-i18next";
import OrderDetails from "../../comp/OrderDetails";
import { FormatMoney } from "../../../utils/formats";
import { loadAddScript } from "../../../utils/advert.js";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import req from "../../../utils/axios";
import getStatus from "../../../utils/tradeStatusCode";
import { setTradeStatus } from "../../../redux/actions";
import { isCreditCard, validateReturnUrl } from "../../../utils/validations";
import AutoQuery from "../../comp/AutoQuery";
import { useMethodConfig } from "../../../utils/configurations";
import PayCash from "../../../img/pay_cash.png";

function Result(props) {
  const { t } = useTranslation();
  const [redirectCountDown, setRedirectCountDown] = useState(5); // redirect countdown
  const [isAutoRedirect, setIsAutoRedirect] = useState(false);
  const methodConfig = useMethodConfig();

  const isAutoQuery = isCreditCard() || props.method === "Wallet" || props.method === "PIX" || props.tradeStatus.query;

  useEffect(() => {
    // console.log("redirect check");
    isAutoRedirect && redirectCountDown <= 0 && window.location.replace(props.returnUrl);
  }, [isAutoRedirect, redirectCountDown]);

  useEffect(() => {
    // redirect countdown
    if (
      (props.tradeStatus.id === "success" || props.tradeStatus.id === "fail") &&
      validateReturnUrl(props.returnUrl) &&
      isAutoQuery
    ) {
      setIsAutoRedirect(true);
      const interval = setInterval(() => {
        setRedirectCountDown((prev) => {
          let newCountDown = prev - 1;
          if (newCountDown <= 0) {
            clearInterval(interval);
          }
          return newCountDown;
        });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [props.tradeStatus.id]);

  return (
    <>
      <AutoQuery />
      <div className="result">
        <div className="result__right">
          <div className="result__payResult">
            {(props.tradeStatus.id === "fail" ||
              props.tradeStatus.id === "process" ||
              props.tradeStatus.id === "other") && (
              <>
                <div className="result__payResult__resultContainer">
                  {props.tradeStatus.id === "fail" && <Failed />}
                  {props.tradeStatus.id === "process" && <Processing />}
                  {props.tradeStatus.id === "other" && <Other />}
                  {/* <div className="result__payResult__noticeContainer">
                    <img className="result__payResult__qMark" src={QMark} alt="" />
                    <div className="result__payResult__notice">
                      <img src={Chat} alt="" />
                      <span>Live chat</span>
                      <p>{t("fail_notice")}</p>
                    </div>
                  </div> */}
                </div>
                {props.tradeStatus.id === "fail" && isAutoRedirect && (
                  <div className="result--success--redirect">
                    <p>{t("payment.redirect.notice1", { key: redirectCountDown })}</p>
                    <p>
                      <span>{t("payment.redirect.notice2")}</span>
                      <span
                        onClick={() => {
                          setIsAutoRedirect(false);
                        }}
                      >
                        {t("payment.redirect.notice3")}
                      </span>
                      <span>{t("payment.redirect.notice4")}</span>
                    </p>
                  </div>
                )}
                {/* {props.tradeStatus.id === "process" && isAutoQuery && (
                  <div className="payResult__refresh">
                    <hr />
                    <p className="payResult__refreshNotice">{t("refresh_notice")}</p>
                    <div className="payResult__refreshButton">
                      <Button
                        variant="contained"
                        className=""
                        disabled={isLoading || countDown > 0}
                        onClick={getQueryStatus}
                        style={{
                          backgroundColor: isQueried ? "#01a27e" : "#fff",
                          color: isQueried ? "#fff" : "#01a27e",
                        }}
                      >
                        {!isLoading && countDown <= 0 && t("refresh")}
                        {!isLoading && countDown > 0 && countDown + "s"}
                      </Button>
                      {isLoading && <CircularProgress size={24} className="payResult__refresh__loading" />}
                    </div>
                  </div>
                )} */}

                {props.tradeStatus.id !== "process" && (
                  <>
                    <p className="result__payResult__title">{t("fail_query")}</p>
                    <div className="result__payResult__buttons">
                      <button
                        className="result__payResult__buttonYes"
                        onClick={() => {
                          props.returnUrl && window.open(props.returnUrl, "_self");
                        }}
                      >
                        {t("yes")}
                      </button>
                      <button
                        className="result__payResult__buttonNo"
                        onClick={() => {
                          window?.LC_API?.open_chat_window();
                        }}
                      >
                        {t("help_me")}
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
            {props.tradeStatus.id === "success" && (
              <div className="result__payResult__success">
                <Success />
                {isAutoRedirect && (
                  <div className="result--success--redirect">
                    <p>{t("payment.redirect.notice1", { key: redirectCountDown })}</p>
                    <p>
                      <span>{t("payment.redirect.notice2")}</span>
                      <span
                        onClick={() => {
                          setIsAutoRedirect(false);
                        }}
                      >
                        {t("payment.redirect.notice3")}
                      </span>
                      <span>{t("payment.redirect.notice4")}</span>
                    </p>
                  </div>
                )}
                {props.returnUrl && (
                  <button
                    onClick={() => {
                      window.open(props.returnUrl, "_self");
                    }}
                    style={{ marginTop: "20px" }}
                  >
                    {t("back_shop")}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        {props?.tradeInfo?.out_trade_no && (
          <div className="result__left">
            <OrderDetails showDetails={true} hideStatus={true} backToShop={true} />
          </div>
        )}
      </div>

      {/* mobile starts here */}
      <div className="resultMobile">
        {props?.tradeInfo?.out_trade_no && (
          <div className="resultMobile__payDetails">
            <TitleWithDetails show={true} />
            <hr />
            <div className="resultMobile__payDetails__container">
              <p className="resultMobile__payDetails__text">{t("total")}</p>
              <p className="resultMobile__payDetails__value">
                <FormatMoney type="total" />
              </p>
            </div>

            {(methodConfig[props.method] || (props.channel?.data?.length === 1 && props.channel?.data?.[0]?.logo)) && (
              <>
                <hr />
                <div className="resultMobile__payDetails__container">
                  <p className="resultMobile__payDetails__text">
                    {props.method === "DepositExpress" ? t("bank") : t("payment_ticket")}
                  </p>
                  <img
                    src={
                      props.method === "Cash" && props.payChannel === "PayCash"
                        ? PayCash
                        : props.channel?.data?.[0]?.logo || methodConfig[props.method]?.info?.image
                    }
                    alt=""
                  />
                </div>
              </>
            )}

            {props.payData?.reference && (
              <>
                <hr />
                <div className="resultMobile__payDetails__payCode">
                  <PaymentCode />
                </div>
              </>
            )}

            {((props.method === "CreditCard" && props.payChannel === "E-Comprocessing") ||
              (props.method === "CreditCard" &&
                props.payChannel === "Paypal" &&
                props.tradeInfo?.app_info?.region === "GLB")) && (
              <div
                style={{
                  fontFamily: "Montserrat-Regular",
                  fontSize: "11px",
                  textAlign: "center",
                  border: "1px dashed #878787",
                  padding: "3px",
                  marginTop: "10px",
                }}
              >
                {props.method === "CreditCard" && props.payChannel === "E-Comprocessing" && (
                  <>
                    <p>{t("ecp_notice1")}</p>
                    <p>{t("ecp_notice2")}</p>
                  </>
                )}
                {props.method === "CreditCard" &&
                  props.payChannel === "Paypal" &&
                  props.tradeInfo?.app_info?.region === "GLB" && (
                    <p>
                      *Depending on your issuing bank, there may be foreign transaction fees charged on this transaction
                    </p>
                  )}
              </div>
            )}
          </div>
        )}
        <div className="resultMobile__payResult">
          {(props.tradeStatus.id === "fail" || props.tradeStatus.id === "success") && isAutoRedirect && (
            <div className="result--success--redirect">
              <p>{t("payment.redirect.notice1", { key: redirectCountDown })}</p>
              <p>
                <span>{t("payment.redirect.notice2")}</span>
                <span
                  onClick={() => {
                    setIsAutoRedirect(false);
                  }}
                >
                  {t("payment.redirect.notice3")}
                </span>
                <span>{t("payment.redirect.notice4")}</span>
              </p>
            </div>
          )}
          {props.tradeStatus.id === "fail" && <Failed />}
          {props.tradeStatus.id === "success" && <Success />}

          {props.tradeStatus.id === "process" && <Processing />}
          {props.tradeStatus.id === "other" && <Other />}
        </div>

        {/* {props.tradeStatus.id === "process" && isAutoQuery && (
          <div className="payResult__refresh">
            <hr />
            <p className="payResult__refreshNotice">{t("refresh_notice")}</p>
            <div className="payResult__refreshButton">
              <Button
                variant="contained"
                className=""
                disabled={isLoading || countDown > 0}
                onClick={getQueryStatus}
                style={{
                  backgroundColor: isQueried ? "#01a27e" : "#fff",
                  color: isQueried ? "#fff" : "#01a27e",
                }}
              >
                {!isLoading && countDown <= 0 && t("refresh")}
                {!isLoading && countDown > 0 && countDown + "s"}
              </Button>
              {isLoading && <CircularProgress size={24} className="payResult__refresh__loading" />}
            </div>
          </div>
        )} */}

        {props.returnUrl && (
          <div className="resultMobile__footer">
            <button
              className="resultMobile__footer__returnButton"
              onClick={() => {
                window.location.replace(props.returnUrl);
              }}
            >
              {t("back_shop")}
            </button>
          </div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    method: state.method,
    payChannel: state.payChannel,
    channel: state.channel,
    tradeInfo: state.tradeInfo,
    channelList: state.channelList,
    paymentInfo: state.paymentInfo,
    returnUrl: state.returnUrl,
    payData: state.payData,
    tradeStatus: state.tradeStatus,
    prepayId: state.prepayId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { setTradeStatus: (item) => dispatch(setTradeStatus(item)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Result);
