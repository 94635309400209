import React from "react";
import "./Success.scss";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import LottiAnimation from "../../comp/LottiAnimation";
function Success(props) {
  const { t } = useTranslation();
  return (
    <div className="success">
      <LottiAnimation anime="success" />
      <p className="success__notice1">{props.tradeStatus?.text ? t(props.tradeStatus?.text) : t("success")}</p>
      <p className="success__notice2">
        {props.tradeStatus?.notice ? t(props.tradeStatus?.notice) : t("success_notice")}
      </p>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeStatus: state.tradeStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Success);
