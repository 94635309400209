import {
  SET_METHOD,
  SET_CHANNEL,
  SET_TRADE_INFO,
  SET_PAYMENT_INFO,
  SET_CHANNEL_LIST,
  SET_RETURN_URL,
  SET_PREPAY_ID,
  SET_PAY_DATA,
  SET_TRADE_STATUS,
  SET_PAY_CHANNEL,
  SET_LOADED_LIBS,
  SET_PAYPAL_CREDITCARD,
  SET_PAYPAL_WALLET,
  SET_GEOLOCATION,
  SET_PROMOTIONS,
  SET_ISAPPLOADING,
  SET_ALERT,
  SET_ISMOBILE,
} from "./types";
import req from "../utils/axios";
import { paypalTokenize } from "../utils/tokanization";
import {
  walletChannels,
  payCashChannels,
  pagoEfectivoBankChannels,
  pagoEfectivoCashChannels,
  getWalletChannelLogo,
  happyPayChannels,
} from "../utils/configurations";
import { formatChannelList, formatMethod, formatPhone } from "../utils/formats";
import uuid from "react-uuid";
import { gaTagData } from "../utils/google-analytics/gaTagData";
import country2codes from "../utils/regionCodes";
import DiscountEn from "../img/discount_en.png";
import DiscountPt from "../img/discount_pt.png";
import DiscountEs from "../img/discount_es.png";
import SpecialPriceEn from "../img/promo_en.png";
import SpecialPriceEs from "../img/promo_es.png";
import SpecialPricePt from "../img/promo_pt.png";
import {
  validateTaxId,
  checkIsPhoneValid,
  checkIsEmailValid,
  checkIsNameValid,
  validateReturnUrl,
} from "../utils/validations";
import { isAddressNeeded } from "../components/form-input/Config/formConfig";
import countryList from "../utils/regionCodes";
import queryUrl from "../utils/queryUrl";
import { loadLib, loadLibs, setLiveChat } from "../utils/loadLibs";
import getStatus from "../utils/tradeStatusCode";
import { jsonParse } from "../utils/jsonParse";
import { handleErrorGlobal } from "../utils/toast";
import { initialState } from "./reducer";

export const setMethod = (item) => {
  return {
    type: SET_METHOD,
    item: item,
  };
};

export const setChannel = (item) => {
  return {
    type: SET_CHANNEL,
    item: item,
  };
};

export const setTradeInfo = (item) => {
  return {
    type: SET_TRADE_INFO,
    item: item,
  };
};

export const setPaymentInfo = (item) => {
  return {
    type: SET_PAYMENT_INFO,
    item: item,
  };
};

export const setChannelList = (item) => {
  return {
    type: SET_CHANNEL_LIST,
    item: item,
  };
};

export const setReturnUrl = (item) => {
  return {
    type: SET_RETURN_URL,
    item: item,
  };
};

export const setPrepayId = (item) => {
  return {
    type: SET_PREPAY_ID,
    item: item,
  };
};

export const setPayData = (item) => {
  return {
    type: SET_PAY_DATA,
    item: item,
  };
};

export const setTradeStatus = (item) => {
  return {
    type: SET_TRADE_STATUS,
    item: item,
  };
};

export const setPayChannel = (item) => {
  return {
    type: SET_PAY_CHANNEL,
    item: item,
  };
};

export const setLoadedLibs = (item) => {
  return {
    type: SET_LOADED_LIBS,
    item: item,
  };
};

export const setPaypalCreditCard = (item) => {
  return {
    type: SET_PAYPAL_CREDITCARD,
    item: item,
  };
};

export const setPaypalWallet = (item) => {
  return {
    type: SET_PAYPAL_WALLET,
    item: item,
  };
};

export const setGeoLocation = (item) => {
  return {
    type: SET_GEOLOCATION,
    item: item,
  };
};

export const setPromotions = (item) => {
  return {
    type: SET_PROMOTIONS,
    item: item,
  };
};

export const setIsAppLoading = (item) => {
  return {
    type: SET_ISAPPLOADING,
    item: item,
  };
};

export const setAlert = (item) => {
  return {
    type: SET_ALERT,
    item: item,
  };
};

export const setDesktopView = (item) => {
  return {
    type: SET_ISMOBILE,
    item: item,
  };
};

export const queryOrder = (prepayId, changeCountry) => {
  return (dispatch, getState) => {
    const checkisOnlyOneMethod = (list) => {
      if (Array.isArray(list)) {
        return formatMethod(list).length === 1;
      } else {
        return false;
      }
    };
    const handleError = (err, notice) => {
      handleErrorGlobal({ err: err, notice_code: notice, defaultErrCode: "fail_try_agin" });
    };
    const handleErrorNotOnly = (err) => {
      handleErrorGlobal({
        err: err,
        setIsLoading: () => {
          dispatch(setIsAppLoading(false));
        },
      });
    };
    let requestUrl = `/api/trade/query?prepay_id=${prepayId}`;
    if (changeCountry) {
      requestUrl = `/api/trade/change-region?prepay_id=${prepayId}&region=${changeCountry}`;
      dispatch(setChannelList(initialState.channelList));
      dispatch(setMethod(initialState.method));
      dispatch(setPayChannel(initialState.payChannel));
      dispatch(setChannel(initialState.channel));
      dispatch(setPaymentInfo(initialState.paymentInfo));
    }
    dispatch(setIsAppLoading(true));
    req
      .post(requestUrl)
      .then((res) => {
        if (res?.data?.trade_no) {
          // const stateBefore = getState();
          // console.log(`tradeInfo before: ${stateBefore.tradeInfo}`);
          dispatch(setTradeInfo(res.data));
          // const stateAfter = getState(); // state is updated immediately with thunk
          // console.log(`tradeInfo after: ${JSON.stringify(stateAfter.tradeInfo)}`);

          const returnUrl = res.data?.return_url || queryUrl("return_url");
          validateReturnUrl(returnUrl) && dispatch(setReturnUrl(returnUrl));

          if (!changeCountry) setLiveChat(res.data);
          let tradeStatus = getStatus(res.data?.trade_status);
          let channelInfoList = Array.isArray(res?.data?.channel_info_list) ? res?.data?.channel_info_list : [];

          if (tradeStatus.id === "trade" && channelInfoList.length === 0) {
            handleError("no channel config", "no_channel");
            return;
          }

          let region = res.data?.app_info?.region;

          dispatch(getGeolocation(region, channelInfoList));
          dispatch(getPromotions(prepayId, channelInfoList));

          if (tradeStatus.id === "trade") {
            let sortedChannelList = channelInfoList.sort((a, b) => a?.sort - b?.sort);
            let isOnlyOneMethod = checkisOnlyOneMethod(channelInfoList);
            let excludedLibs = {};
            if (window.location.pathname === "/authorization") {
              dispatch(setIsAppLoading(false));
            } else {
              if (sortedChannelList[0].method === "Wallet") {
                let isOnlyOneMethodAndChannel =
                  isOnlyOneMethod && sortedChannelList.filter((item) => item.method === "Wallet").length === 1;
                if (sortedChannelList[0].channel === "Paypal" && region === "BRA") {
                  excludedLibs = { ...excludedLibs, "Wallet-Paypal": true };

                  loadLib("Wallet", "Paypal", (item) => {
                    dispatch(setLoadedLibs(item));
                  })
                    .then(() => {
                      dispatch(
                        setWalletChannels(
                          sortedChannelList[0].method,
                          isOnlyOneMethodAndChannel ? handleError : handleErrorNotOnly,
                          (item) => {
                            dispatch(setIsAppLoading(item));
                          }
                        )
                      );
                    })
                    .catch(() => {
                      console.error("loadLib Error!");
                    });
                } else {
                  dispatch(
                    setWalletChannels(
                      sortedChannelList[0].method,
                      isOnlyOneMethodAndChannel ? handleError : handleErrorNotOnly,
                      (item) => {
                        dispatch(setIsAppLoading(item));
                      },
                      region
                    )
                  );
                }
              } else {
                dispatch(
                  changeMethod(
                    sortedChannelList[0].method,
                    sortedChannelList[0].channel,
                    (item) => {
                      dispatch(setIsAppLoading(item));
                    },
                    isOnlyOneMethod ? handleError : handleErrorNotOnly,
                    undefined,
                    region
                  )
                );
                // use loadLib (promise) then call api above for default ones in need
              }
            }
            loadLibs({
              channels: channelInfoList,
              region: region,
              setLibs: (item) => {
                dispatch(setLoadedLibs(item));
              },
              excludedLibs: excludedLibs,
              prepayId: prepayId,
              isMultiRegion: res.data?.app_info?.global,
            });
          } else {
            if (res.data?.channel === "NaverPay" && queryUrl("error_message")) {
              tradeStatus = {
                id: "fail",
                text: "failed",
                notice: queryUrl("error_message"),
              };
            }
            dispatch(setTradeStatus(tradeStatus));
            if (localStorage?.getItem("PS_" + prepayId + "payData")) {
              dispatch(setPayData(jsonParse(localStorage?.getItem("PS_" + prepayId + "payData"))));
            } else {
              dispatch(setPayData(res.data?.payment_response));
            }
            if (localStorage?.getItem("PS_" + prepayId + "channel")) {
              dispatch(setChannel(jsonParse(localStorage?.getItem("PS_" + prepayId + "channel"))));
            }
            res.data?.method && dispatch(setMethod(res.data?.method));
            res.data?.channel && dispatch(setPayChannel(res.data?.channel));
            dispatch(setIsAppLoading(false));
          }
        } else {
          //handle exeption
          if (res.data?.code === "40002" && res.data?.sub_code === "timed-out") {
            dispatch(
              setTradeStatus({
                id: "other",
                text: "40101", // i18n id
                notice: "order_timeout", // i18n id
              })
            );
            dispatch(setIsAppLoading(false));
          } else {
            handleError(`unknown error code: ${res?.data?.code}`);
          }
        }
      })
      .catch((err) => {
        if (err && !err.response) {
          handleError(err, "network_err");
        } else {
          handleError(err);
        }
      });
  };
};

export const getGeolocation = (region, channelList) => {
  return (dispatch, getState) => {
    if (
      region === "GLB" ||
      region === "EUP" ||
      channelList?.find((item) => isAddressNeeded(item.method, item.channel, region))
    ) {
      req
        .get("https://api.country.is/")
        .then((res) => {
          let locationObj = res.data?.country
            ? country2codes.find((item) => item.alpha2_code === res.data?.country)
            : "";
          if (locationObj) {
            dispatch(setGeoLocation(locationObj));
          }
        })
        .catch(() => {
          console.log("getGeolocation API failed!");
        });
    }
  };
};

export const getPromotions = (prepayId, channelList) => {
  return (dispatch) => {
    channelList?.forEach((item) => {
      if (item.method === "AlipayPlus" && item.channel === "AlipayPlus") {
        req.post(`/api/trade/bank-promotions?prepay_id=${prepayId}`).then((res) => {
          if (res?.data?.code === "10000" && res?.data?.data) {
            dispatch(
              setPromotions({
                AlipayPlus: {
                  AlipayPlus: {},
                  discountNotice: {
                    es: DiscountEs,
                    en: DiscountEn,
                    pt: DiscountPt,
                  },
                  specialPrice: {
                    es: SpecialPriceEs,
                    en: SpecialPriceEn,
                    pt: SpecialPricePt,
                  },
                },
              })
            );
          }
        });
      } else if (
        (item.method === "PlayersBankPIX" && item.channel === "BS2") ||
        (item.method === "PlayersBankCard" && item.channel === "Paypal")
      ) {
        dispatch(
          setPromotions({
            PlayersBankPIX: {
              BS2: {},
              discountNotice: {
                es: DiscountEs,
                en: DiscountEn,
                pt: DiscountPt,
              },
              specialPrice: {
                es: SpecialPriceEs,
                en: SpecialPriceEn,
                pt: SpecialPricePt,
              },
            },
            PlayersBankCard: {
              BS2: {},
              discountNotice: {
                es: DiscountEs,
                en: DiscountEn,
                pt: DiscountPt,
              },
              specialPrice: {
                es: SpecialPriceEs,
                en: SpecialPriceEn,
                pt: SpecialPricePt,
              },
            },
          })
        );
      }
    });
  };
};

const queriedIDs = [];
export const getInfoById = (setForm, { type = "", id = "" }) => {
  return (dispatch, getState) => {
    const { prepayId, method, tradeInfo } = getState();
    id = id.replace(/[^a-zA-Z0-9]/g, "");
    if (method === "PIX" && validateTaxId(id, "BRA", type) && !queriedIDs.includes(id)) {
      setForm((prev) => {
        return {
          ...prev,
          email: { ...prev.email, show: true },
          phone: { ...prev.phone, show: true },
          name: { ...prev.name, show: true },
        };
      });
      req.post(`/api/trade/cpf?prepay_id=${prepayId}`, { type: type, number: id }).then((res) => {
        const data = res?.data?.data;
        if (res?.data?.code === "10000") {
          queriedIDs.push(id);
          const emailValidity = !tradeInfo?.user?.email && checkIsEmailValid(data.email);
          const phoneValidity =
            !checkIsPhoneValid(tradeInfo?.user?.phone, "BRA") && checkIsPhoneValid(data.phone, "BRA");
          const nameValidity = !tradeInfo?.user?.username && checkIsNameValid(data.name || "");

          setForm((prev) => {
            return {
              ...prev,
              name: nameValidity ? { ...prev.name, value: data.name, valid: true, checked: true } : { ...prev.name },
              email: emailValidity
                ? { ...prev.email, value: data.email, valid: true, checked: true }
                : { ...prev.email },
              phone: phoneValidity
                ? {
                    ...prev.phone,
                    countryObj: countryList.find((item) => item?.alpha3_code === "BRA"),
                    value: formatPhone(data.phone, "BR", true),
                    valid: true,
                    checked: true,
                  }
                : { ...prev.phone },
            };
          });
        }
      });
    }
  };
};

export const changeMethod = (method, channel, setIsLoading = () => {}, handleError = () => {}, callback = () => {}) => {
  return (dispatch, getState) => {
    const { prepayId, channelList, tradeInfo, paypalWallet } = getState();
    channel = channel ? channel : tradeInfo?.channel_info_list.find((item) => item.method === method).channel;
    setIsLoading(true);
    const region = tradeInfo?.app_info?.region;
    let requestUrl = `/api/trade/change-method?prepay_id=${prepayId}&method=${method}&channel=${channel}`;
    if (tradeInfo?.app_info?.global) requestUrl += `&region=${region}`;
    req
      .post(requestUrl)
      .then((res) => {
        if (res?.data?.code === "10000") {
          dispatch(setPaymentInfo(res.data.data));
          if (
            (method === "CreditCard" || method === "RecurringCreditCard" || method === "PlayersBankCard") &&
            channel === "Paypal"
          ) {
            paypalTokenize(
              method,
              channel,
              prepayId,
              (token) => {
                setIsLoading(false);
                dispatch(setMethod(method));
                dispatch(setPayChannel(channel));
                dispatch(setChannel(""));
                dispatch(setPaypalCreditCard({ authorizationToken: token }));
              },
              (err) => {
                // callback for fail
                handleError(err);
              }
            );
          } else if (method === "Wallet" && channel === "Paypal" && region === "BRA") {
            if (paypalWallet.isReady) {
              dispatch(setPayChannel(channel));
              callback();
              setIsLoading(false);
            } else {
              paypalTokenize(
                method,
                channel,
                prepayId,
                (token) => {
                  setIsLoading(false);
                  dispatch(setPaypalWallet({ authorizationToken: token }));
                  dispatch(setPayChannel(channel));
                  // dont call callback here! callback in Channel component setChannel in callback, paypal to setChannel after paypal button is rendered
                },
                (err) => {
                  // callback for fail
                  handleError(err);
                }
              );
            }
          } else if (method === "Wallet") {
            // only called by clicking agents after method is setted and channels are appeared so no need to dispatch method
            dispatch(setPayChannel(channel));
            callback();
            setIsLoading(false);
          } else if (
            (method === "DepositExpress" && channel === "Levpay") ||
            (method === "PagoEfectivo" && channel === "PagoEfectivo") ||
            (method === "DirectDebit" && channel === "Xendit") ||
            (method === "Crypto" && channel === "TripleA")
          ) {
            // only called by clicking methods which has agent to be selected
            dispatch(setMethod(method));
            dispatch(setPayChannel(channel));
            dispatch(setChannel(channelList[method]?.[0]));
            callback();
            setIsLoading(false);
          } else if (method === "Cash" && channel === "PayCash") {
            dispatch(setMethod(method));
            dispatch(setPayChannel(channel));
            dispatch(
              setChannelList({
                ...channelList,
                Cash: payCashChannels[tradeInfo?.app_info?.region],
              })
            );
            dispatch(setChannel(payCashChannels[tradeInfo?.app_info?.region]?.[0]));
            callback();
            setIsLoading(false);
          } else if ((method === "BankTransfer" || method === "OTC") && channel === "HappyPay") {
            dispatch(setMethod(method));
            dispatch(setPayChannel(channel));
            dispatch(
              setChannelList({
                ...channelList,
                [method]: happyPayChannels[method],
              })
            );
            dispatch(setChannel(happyPayChannels[method]?.[0]));
            callback();
            setIsLoading(false);
          } else if (
            (method === "BankTransfer" && channel === "PagoEfectivo") ||
            (method === "Cash" && channel === "PagoEfectivo")
          ) {
            dispatch(setMethod(method));
            dispatch(setPayChannel(channel));
            dispatch(
              setChannelList({
                ...channelList,
                [method]: method === "Cash" ? pagoEfectivoCashChannels : pagoEfectivoBankChannels,
              })
            );
            dispatch(setChannel(method === "Cash" ? pagoEfectivoCashChannels[0] : pagoEfectivoBankChannels[0]));
            callback();
            setIsLoading(false);
          } else if (
            ((method === "BankTransfer" || method === "Cash") && channel === "SafetyPay") ||
            (method === "Khipu" && channel === "Khipu") ||
            (method === "BankTransfer" && channel === "Localpayment") ||
            (method === "Cash" && channel === "Localpayment") ||
            (method === "OpenFinance" && channel === "Mercadopago") ||
            (method === "OpenFinance" && channel === "Iniciador") ||
            (method === "PSE" && channel === "ACH")
          ) {
            dispatch(getChannels(method, channel, setIsLoading, handleError));
          } else {
            // only called by clicking methods which does not have any agent to be selected
            dispatch(setMethod(method));
            dispatch(setPayChannel(channel));
            dispatch(setChannel(""));
            callback();
            setIsLoading(false);
          }
        } else {
          handleError("");
        }
      })
      .catch((err) => {
        if (err && !err.response) {
          handleError(err, "network_err");
        } else {
          handleError(err, "fail_change_method");
        }
      });
  };
};

const sortAsLength = (data) => {
  return data.sort((a, b) => a?.data?.length - b?.data?.length);
};

export const getChannels = (method, channel, setIsLoading = () => {}, handleError = () => {}) => {
  return (dispatch, getState) => {
    const { prepayId, channelList, tradeInfo } = getState();
    if (!channelList[method]?.length) {
      const reqData = {
        data: { method: method, channel: channel },
        url: "/api/trade/get-banktransfer-channel",
        method: "post",
      };
      //const postData = { method: method, channel: channel };
      if (tradeInfo?.app_info?.global) reqData.data.region = tradeInfo?.app_info?.region;
      if (method === "Cash" && channel === "Localpayment") reqData.data.type = "2";
      //let endpoint = "/api/trade/get-banktransfer-channel";
      if (method === "OpenFinance" && channel === "Mercadopago") {
        reqData.url = "/api/trade/get-bank-list";
      } else if ((method === "PSE" && channel === "ACH") || (method === "OpenFinance" && channel === "Iniciador")) {
        reqData.url = "/api/trade/channel-bank-list";
        reqData.method = "get";
      }
      req({ url: `${reqData.url}?prepay_id=${prepayId}`, data: reqData.data, method: reqData.method })
        .then((res) => {
          if (res.data?.code === "10000") {
            var data = res.data?.data;
            if ((method === "Cash" || method === "BankTransfer") && channel === "SafetyPay") {
              if (tradeInfo?.app_info?.region === "PER") {
                let valueOnline = [];
                let valueCash = { bank: [], agent: [] };
                data?.forEach((item) => {
                  if (item.channel === "Cash") {
                    item.tag === "Person" ? valueCash.bank.push(item) : valueCash.agent.push(item);
                  } else if (item.channel === "Online") {
                    valueOnline.push(item);
                  }
                });
                if (method === "Cash") {
                  let bankList = sortAsLength(formatChannelList(valueCash.bank));
                  let agentList = sortAsLength(formatChannelList(valueCash.agent));
                  dispatch(
                    setChannelList({
                      ...channelList,
                      Cash: {
                        bank: bankList,
                        agent: agentList,
                      },
                    })
                  );
                  dispatch(setChannel(agentList[0]));
                } else if (method === "BankTransfer") {
                  let onlineList = sortAsLength(formatChannelList(valueOnline));
                  dispatch(
                    setChannelList({
                      ...channelList,
                      BankTransfer: onlineList,
                    })
                  );
                  dispatch(setChannel(onlineList[0]));
                }
              } else {
                let valueOnline = [];
                let valueCash = [];
                data?.forEach((item) => {
                  if (item.channel === "Online") {
                    valueOnline.push(item);
                  } else if (item.channel === "Cash") {
                    valueCash.push(item);
                  }
                });

                if (method === "Cash") {
                  let cashList = sortAsLength(formatChannelList(valueCash));
                  dispatch(
                    setChannelList({
                      ...channelList,
                      Cash: cashList,
                    })
                  );
                  dispatch(setChannel(cashList[0]));
                } else if (method === "BankTransfer") {
                  let onlineList = sortAsLength(formatChannelList(valueOnline));
                  dispatch(
                    setChannelList({
                      ...channelList,
                      BankTransfer: onlineList,
                    })
                  );
                  dispatch(setChannel(onlineList[0]));
                }
              }
            } else {
              let list = sortAsLength(formatChannelList(data));
              dispatch(
                setChannelList({
                  ...channelList,
                  [method]: list,
                })
              );
              dispatch(setChannel(list[0]));
            }
            dispatch(setMethod(method));
            dispatch(setPayChannel(channel));
            setIsLoading(false);
          } else {
            //handle exeption
            handleError("get-banktransfer-channel endpoint error!");
          }
        })
        .catch((err) => {
          if (err && !err.response) {
            handleError(err, "network_err");
          } else {
            handleError(err, "fail_change_method");
          }
        });
    } else {
      dispatch(setMethod(method));
      dispatch(setPayChannel(channel));
      dispatch(setChannel(""));
      setIsLoading(false);
    }
  };
};

export const setWalletChannels = (method, handleError = () => {}, setIsLoading = () => {}) => {
  //console.log(defaultChannel);
  return (dispatch, getState) => {
    const { tradeInfo, channelList } = getState();
    try {
      dispatch(setMethod(method));
      dispatch(setPayChannel(""));
      let walletChannelList = tradeInfo?.channel_info_list
        ?.sort((a, b) => a?.sort - b?.sort)
        ?.reduce((filtered, item) => {
          if (item.method === "Wallet") {
            filtered.push({
              data: [
                {
                  logo: getWalletChannelLogo(item.channel),
                  channel: item.channel,
                },
              ],
              key: uuid(),
              "ga-data": gaTagData[tradeInfo?.app_info?.region]?.channel?.[item.channel] || "",
            });
          }
          return filtered;
        }, []);

      dispatch(
        setChannelList({
          ...channelList,
          [method]: walletChannelList,
        })
      );
      dispatch(
        changeMethod(
          // default select the first channel
          method,
          walletChannelList[0].data[0].channel,
          setIsLoading,
          handleError,
          () => {
            dispatch(setChannel(walletChannelList[0]));
          }
        )
      );
    } catch {
      handleError("setWalletChannels error!");
    }
  };
};
