import React from "react";
import { connect } from "react-redux";
import { Redirect, useRouteMatch } from "react-router-dom";
import { checkoutConfig } from "../../utils/configurations";
import { returnUrlAsParam } from "../../utils/queryUrl";

function RequireAuth({ children, ...props }) {
  const match = useRouteMatch();
  const tradeStatus = props.tradeStatus.id;
  const checkoutPageAuth = checkoutConfig.includes(`${props.method}-${props.payChannel}`);
  const queryParams = `?prepay_id=${props.prepayId}${returnUrlAsParam()}`;
  // console.log("match :", match);
  // console.log("tradeStatus: ", tradeStatus);
  if (match.path === "/") {
    if (tradeStatus !== "trade") {
      if (tradeStatus === "process" && checkoutPageAuth) {
        return <Redirect to={{ pathname: "/checkout", search: queryParams }} />;
      } else {
        return <Redirect to={{ pathname: "/result", search: queryParams }} />;
      }
    }
  } else if (match.path === "/checkout") {
    if (tradeStatus === "trade") {
      return <Redirect to={{ pathname: "/", search: queryParams }} />;
    } else if (tradeStatus === "success" || tradeStatus === "fail" || tradeStatus === "other" || !checkoutPageAuth) {
      return <Redirect to={{ pathname: "/result", search: queryParams }} />;
    }
  } else if (match.path === "/result") {
    if (tradeStatus === "trade") {
      return <Redirect to={{ pathname: "/", search: queryParams }} />;
    }
  } else if (match.path === "/refund-confirmation") {
    if (tradeStatus !== "trade") {
      return <Redirect to={{ pathname: "/result", search: queryParams }} />;
    }
  }

  return children;
}

const mapStateToProps = (state, ownprops) => {
  return {
    method: state.method,
    payChannel: state.payChannel,
    tradeStatus: state.tradeStatus,
    prepayId: state.prepayId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RequireAuth);

