import React from "react";
import { connect } from "react-redux";
import { FormatMoney } from "../../utils/formats";
import "./Subscription.scss";
import { useTranslation } from "react-i18next";

function Subscription(props) {
  const { t, i18n } = useTranslation();
  const char2Time = (key, number) => {
    const convert = {
      D: number == "1" ? t("day") : t("days"),
      W: number == "1" ? t("week") : t("weeks"),
      M: number == "1" ? t("month") : t("months"),
      Y: number == "1" ? t("year") : t("years"),
    };

    return convert[key];
  };
  const interval = props.tradeInfo.recurring?.recurring_interval?.replace(/[^\d]/g, "");
  const intervalTime = props.tradeInfo.recurring?.recurring_interval?.replace(/\d/g, "").toUpperCase();
  const trialTime = props.tradeInfo.recurring?.trial_period?.replace(/\d/g, "").toUpperCase();
  const trial = props.tradeInfo.recurring?.trial_period?.replace(/[^\d]/g, "");

  return (
    <p className="subscription">
      <span>{t("subscription")}</span>
      {props.tradeInfo.recurring?.total_stage ? (
        <span>
          {" "}
          {i18n.language === "en" ? "to " : ""}
          {props.tradeInfo?.order_amount && (
            <>
              <span className="highlighted">{trial + " " + char2Time(trialTime, trial) + " " + t("trial_period")}</span>
              <span>{` ${t("and")} `}</span>
            </>
          )}
          <span className="highlighted">
            {props.tradeInfo.recurring?.total_stage * interval +
              " " +
              char2Time(intervalTime, props.tradeInfo.recurring?.total_stage * interval)}
          </span>
        </span>
      ) : (
        ""
      )}
      {props.tradeInfo?.order_amount ? (
        <span>
          {`, ${t("subscription_billed")} `}
          <span className="highlighted">
            <FormatMoney type="total" /> {t("subscription_trial")}
          </span>
          {`${t("subscription_then")} `}
          <span className="highlighted">
            <FormatMoney type="order" /> {t("subscription_every")} {interval} {char2Time(intervalTime, interval)}
          </span>
          .
        </span>
      ) : (
        <>
          {`, ${t("subscription_billed")} `}
          <span className="highlighted">
            <FormatMoney type="total" /> {t("subscription_every")} {interval} {char2Time(intervalTime, interval)}.
          </span>
        </>
      )}
    </p>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    paymentInfo: state.paymentInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
