import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import BtcQr from "../../../img/btc.svg";
import EthQr from "../../../img/eth_logo.png";
import LnbcQr from "../../../img/lnbc_logo.png";
import UsdcQr from "../../../img/usdc_logo.png";
import UsdtQr from "../../../img/tron_logo.png";
import CopyButton from "../../comp/CopyButton";
import { useTranslation } from "react-i18next";
var QRCode = require("qrcode.react");

// https://developers.triple-a.io/docs/triplea-api-doc/679d678d3f3bb-step-by-step-guide-for-crypto-payments#step-2-load-the-payment-form
function CheckoutCryptoOld() {
  const paydata = useSelector((state) => state.payData);
  useEffect(() => {
    window.addEventListener(
      "message",
      (e) => {
        console.debug("message received:", e.data);
        let iframe = document.getElementById("triplea-payment-form");
        if (typeof e.data === "string" && e.data.indexOf("|") > 0) {
          const res = e.data.split("|");
          if (iframe && res[0] === "triplea.frameResized") {
            iframe.style.height = res[1] + "px";
          }
        }
      },
      false
    );
  }, []);
  return (
    <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
      <iframe
        id="triplea-payment-form"
        src={paydata?.qr_code_url + "&order_currency=hide"}
        style={{ width: "auto", height: "700px", border: "0" }}
        title="TripleA Payment Form"
        scrolling="no"
      ></iframe>
    </div>
  );
}

function CheckoutCrypto() {
  const payData = useSelector((state) => state.payData);
  const { t } = useTranslation();
  const crypto = {
    testBTC: {
      logo: BtcQr,
      currency: "testBTC",
      network: "Bitcoin network only",
    },
    BTC: {
      logo: BtcQr,
      currency: "BTC",
      network: "Bitcoin network only",
    },
    LNBC: {
      logo: LnbcQr,
      currency: "sats",
      network: "Please send to invoice",
    },
    ETH: {
      logo: EthQr,
      currency: "ETH",
      network: "Ethereum network only",
    },
    USDC: {
      logo: UsdcQr,
      currency: "USDC",
      network: "Ethereum network only",
    },
    USDT: {
      logo: UsdtQr,
      currency: "USDT",
      network: "Ethereum network only",
    },
    USDT_TRC20: {
      logo: UsdtQr,
      currency: "USDT",
      network: "Tron network only",
    },
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#ffffff",
        fontFamily: "Montserrat-Regular",
        paddingBottom: "20px",
      }}
    >
      <div style={{ maxWidth: "400px", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <p style={{ margin: "5px 0", fontSize: "13px", fontWeight: "700" }}>{t("crypto_1")}</p>
        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
          <h3 style={{ margin: "5px 0" }}>
            {payData.crypto_amount}{" "}
            <span style={{ fontSize: "13px" }}>
              {crypto[payData?.crypto_currency]?.currency || payData?.crypto_currency}
            </span>
          </h3>
          <CopyButton text={payData?.crypto_amount} onlyIcon minWidth={50} />
        </div>
        {payData?.crypto_currency !== "LNBC" && <p style={{ fontSize: "13px", fontWeight: "700" }}>{t("crypto_2")}</p>}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center",
            border: "1px #fba557 dashed",
            borderRadius: "5px",
          }}
        >
          <p
            style={{
              backgroundColor: "#fba557",
              width: "100%",
              textAlign: "center",
              margin: "0",
              outline: "1px solid #fba557",
              fontSize: "12px",
              fontWeight: "700",
            }}
          >
            {crypto[payData?.crypto_currency]?.network}
          </p>
          <p
            style={{ fontSize: "12px", wordBreak: "break-all", padding: "5px", borderRadius: "5px", fontWeight: "700" }}
          >
            {payData?.crypto_address}
          </p>
          <CopyButton text={payData?.crypto_address} onlyIcon minWidth={50} />
        </div>
        <p style={{ fontSize: "13px", fontWeight: "700" }}>{t("crypto_3")}</p>

        {payData?.crypto_address && (
          <>
            <QRCode
              value={payData?.crypto_address}
              size={156}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"M"}
              includeMargin={false}
              renderAs={"svg"}
              imageSettings={{
                src: crypto[payData?.crypto_currency]?.logo || BtcQr,
                x: null,
                y: null,
                height: 24,
                width: 24,
                excavate: true,
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default CheckoutCryptoOld;
