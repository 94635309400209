import React from "react";
import "./PaymentMethod.scss";
import Selected from "../../../img/check.svg";
import DiscountEn from "../../../img/discount_en.png";
import DiscountPt from "../../../img/discount_pt.png";
import DiscountEs from "../../../img/discount_es.png";
import { connect } from "react-redux";
import { changeMethod, setWalletChannels, setPaymentInfo } from "../../../redux/actions";
import { useTranslation } from "react-i18next";
import { gaTagData } from "../../../utils/google-analytics/gaTagData";
var classNames = require("classnames");

const discountNotice = {
  es: DiscountEs,
  en: DiscountEn,
  pt: DiscountPt,
};

function PaymentMethod({ image, text, type, channel, loading = () => {}, handleError, ...props }) {
  const { t, i18n } = useTranslation();
  const gaData = gaTagData[props.tradeInfo?.app_info?.region]?.method?.[type] || "";

  const handleClick = () => {
    if (type === "Wallet") {
      props.setPaymentInfo("");
      props.setWalletChannels(type, handleError, loading);
    } else {
      props.changeMethod(type, channel, loading, handleError);
    }
  };

  return (
    <>
      <div className="method" onClick={handleClick} ga-data={gaData}>
        <div className="method__logoContainer" ga-data={gaData}>
          <img
            className={classNames("method__logo", {
              "method--filter": props.method !== type,
            })}
            src={image}
            alt=""
            ga-data={gaData}
          />
          {props.method === type && <img className="method__selected" src={Selected} alt="" />}
          {props.promotions?.[type] && (
            <img
              className="method__promotion"
              src={props.promotions?.[type]?.specialPrice[i18n.language] || props.promotions?.[type]?.specialPrice.en}
              alt=""
            />
          )}
        </div>

        <p className="method__text" ga-data={gaData}>
          {text}
        </p>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    method: state.method,
    tradeInfo: state.tradeInfo,
    promotions: state.promotions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeMethod: (method, channel, setIsLoading, handleError) =>
      dispatch(changeMethod(method, channel, setIsLoading, handleError)),
    setWalletChannels: (method, handleError, loading) => dispatch(setWalletChannels(method, handleError, loading)),
    setPaymentInfo: (item) => dispatch(setPaymentInfo(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
