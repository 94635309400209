import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setPaypalWallet, setChannel, setPayChannel } from "../../../redux/actions";
import {
  checkIsNameValid,
  checkIsEmailValid,
  checkIsPhoneValid,
  validateTaxId,
  checkIsSourceValid,
  checkName,
  checkEmail,
  checkTaxId,
  checkPhone,
  checkSource,
} from "../../../utils/validations";

function PaypalButton({ setIsloading, handleError, setForm, containerId, payRequest, mobile, ...props }) {
  const [nonce, setNonce] = useState("");
  const [deviceData, setDeviceData] = useState("");

  const nameNodeId = mobile ? "inputform-name-mob" : "inputform-name";
  const phoneNodeId = mobile ? "inputform-phone-mob" : "inputform-phone";
  const emailNodeId = mobile ? "inputform-email-mob" : "inputform-email";
  const taxIdNodeId = mobile ? "inputform-taxid-mob" : "inputform-taxid";
  const sourceNodeId = mobile ? "inputform-source-mob" : "inputform-source";

  const checkIsAllValid = () => {
    return (
      checkIsNameValid(document.getElementById(nameNodeId)?.value) &&
      checkIsPhoneValid(document.getElementById(phoneNodeId)?.value, "BRA") &&
      checkIsEmailValid(document.getElementById(emailNodeId)?.value) &&
      validateTaxId(document.getElementById(taxIdNodeId)?.value, "BRA") &&
      (!props.tradeInfo?.is_original_source_required || checkIsSourceValid(document.getElementById(sourceNodeId)?.value))
    );
  };

  useEffect(() => {
    nonce && payRequest({ nonce: nonce, deviceData: deviceData });
  }, [nonce]);

  useEffect(() => {
    let paypalClientInstance = "";
    if (props.loadedLibs["Wallet-Paypal"] && !props.paypalWallet.isReady) {
      setIsloading(true);
      window.braintree.client
        .create({
          authorization: props.paypalWallet.authorizationToken,
        })
        .then(function (clientInstance) {
          paypalClientInstance = clientInstance;
          return window.braintree.dataCollector.create({
            client: clientInstance,
            paypal: true,
          });
        })
        .then(function (dataCollectorInstance) {
          // At this point, you should access the dataCollectorInstance.deviceData value and provide it
          // to your server, e.g. by injecting it into your form as a hidden input
          let myDeviceData = dataCollectorInstance.deviceData;
          //console.log(`Device data: ${myDeviceData}`);
          setDeviceData(myDeviceData);
          // Initialize your PayPal Checkout component here
          //return braintree.paypalCheckout.create(/* ... */);
          return window.braintree.paypalCheckout.create({
            client: paypalClientInstance,
          });
        })
        .then(function (paypalCheckoutInstance) {
          return paypalCheckoutInstance.loadPayPalSDK({
            vault: true,
          });
        })
        .then(function (paypalCheckoutInstance) {
          return window.paypal
            .Buttons({
              fundingSource: window.paypal.FUNDING.PAYPAL,
              createBillingAgreement: function () {
                return paypalCheckoutInstance.createPayment({
                  flow: "vault", // Required
                });
              },
              style: {
                shape: "rect",
                height: 52,
              },
              onInit: function (data, actions) {
                //console.log("oninit");

                const setButtonAction = () => {
                  if (checkIsAllValid()) {
                    actions.enable();
                  } else {
                    actions.disable();
                  }
                };
                setButtonAction();

                document.getElementById(nameNodeId).oninput = setButtonAction;
                document.getElementById(taxIdNodeId).oninput = setButtonAction;
                document.getElementById(emailNodeId).oninput = setButtonAction;
                document.getElementById(phoneNodeId).oninput = setButtonAction;
                if (props.tradeInfo?.is_original_source_required) {
                  document.getElementById(sourceNodeId).oninput = setButtonAction;
                }
              },

              onClick: function () {
                checkName({ setForm: setForm, data: document.getElementById(nameNodeId)?.value });
                checkPhone({ setForm: setForm, region: "BRA", data: document.getElementById(phoneNodeId)?.value });
                checkEmail({ setForm: setForm, data: document.getElementById(emailNodeId)?.value });
                checkTaxId({
                  setForm: setForm,
                  region: "BRA",
                  data: { number: document.getElementById(taxIdNodeId)?.value },
                });
                if (props.tradeInfo?.is_original_source_required) {
                  checkSource({ setForm: setForm, data: document.getElementById(sourceNodeId)?.value });
                }
              },
              onApprove: function (data, actions) {
                return paypalCheckoutInstance.tokenizePayment(data).then(function (payload) {
                  // Submit `payload.nonce` to your server
                  //console.log(`nonce is: ${payload.nonce}`);
                  setNonce(payload.nonce);
                });
              },

              onCancel: function (data) {
                console.log("PayPal payment canceled", JSON.stringify(data, 0, 2));
              },

              onError: function (err) {
                handleError(`Paypal Error: ${err}`);
              },
            })
            .render("#" + containerId);
        })
        .then(function () {
          //console.log("button rendered");

          props.setPaypalWallet({ isReady: true });
          props.setChannel(
            props.channelList["Wallet"]?.find((item) => {
              return item?.data[0]?.channel === "Paypal";
            })
          );
          props.setPayChannel("Paypal");
          setIsloading(false);
          // The PayPal button will be rendered in an html element with the ID
          // `paypal-button`. This function will be called when the PayPal button
          // is set up and ready to be used
        })
        .catch(function (err) {
          handleError(`Paypal Error: ${err}`);
          props.setPayChannel("");
          // Handle component creation error
        });
    } else {
      handleError("Paypal Wallet libs are not loaded successfully!!");
    }
  }, []);
  return (
    <div
      id={containerId}
      style={{
        display:
          props.paypalWallet.isReady && props.method === "Wallet" && props.payChannel === "Paypal" ? "block" : "none",
      }}
    ></div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    paypalWallet: state.paypalWallet,
    paymentInfo: state.paymentInfo,
    tradeInfo: state.tradeInfo,
    returnUrl: state.returnUrl,
    loadedLibs: state.loadedLibs,
    channelList: state.channelList,
    method: state.method,
    payChannel: state.payChannel,
    prepayId: state.prepayId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPaypalWallet: (item) => dispatch(setPaypalWallet(item)),
    setChannel: (item) => dispatch(setChannel(item)),
    setPayChannel: (item) => dispatch(setPayChannel(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaypalButton);
