import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setPaypalWallet, setChannel, setPayChannel } from "../../../redux/actions";
import req from "../../../utils/axios";

function PaypalButtonNative({ setIsloading, handleError, setForm, containerId, payRequest, ...props }) {
  const [token, setToken] = useState(null);
  useEffect(() => {
    //console.log("token set: ", token);
    token && payRequest({ token });
  }, [token]);

  useEffect(() => {
    if (props.loadedLibs["Wallet-Paypal-MEX"] && !props.paypalWallet.isReady) {
      setIsloading(true);
      window?.paypal
        ?.Buttons({
          onInit(data, actions) {
            setIsloading(false);
            props.setPaypalWallet({ isReady: true });

            const policyCheckbox = document.querySelector("#policy-checkbox");
            !policyCheckbox.checked && actions.disable();

            // Listen for changes to the checkbox
            policyCheckbox?.addEventListener("change", function (event) {
              // Enable or disable the button when it is checked or unchecked
              if (event.target.checked) {
                actions.enable();
              } else {
                actions.disable();
              }
            });
          },
          // Order is created on the server and the order id is returned
          createOrder() {
            return req
              .post(`/api/trade/paypal/authorize?prepay_id=${props.prepayId}`)
              .then((response) => {
                if (response?.data?.code === "10000" && response?.data?.data?.token) {
                  return response?.data?.data?.token;
                } else {
                  handleError("/paypal/authorize endpoint error!");
                }
              })
              .catch((err) => {
                handleError(err);
              });
          },
          // Finalize the transaction on the server after payer approval
          onApprove(data) {
            //console.log("paypal data: ", data);
            setToken(data.orderID);
          },
          onError(err) {
            // For example, redirect to a specific error page
            // closeing popup generates not specific error!
          },
        })
        .render(`#${containerId}`);
    }
  }, [props.loadedLibs["Wallet-Paypal-MEX"]]);
  return (
    <div
      id={containerId}
      style={{
        display:
          props.paypalWallet.isReady && props.method === "Wallet" && props.payChannel === "Paypal" ? "block" : "none",
      }}
    ></div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    paypalWallet: state.paypalWallet,
    paymentInfo: state.paymentInfo,
    tradeInfo: state.tradeInfo,
    returnUrl: state.returnUrl,
    loadedLibs: state.loadedLibs,
    channelList: state.channelList,
    method: state.method,
    payChannel: state.payChannel,
    prepayId: state.prepayId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPaypalWallet: (item) => dispatch(setPaypalWallet(item)),
    setChannel: (item) => dispatch(setChannel(item)),
    setPayChannel: (item) => dispatch(setPayChannel(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaypalButtonNative);
