import React from "react";
import "./CheckoutTitle.scss";
import { useTranslation } from "react-i18next";
import { FormatMoney } from "../../../utils/formats";
import { connect } from "react-redux";

function CheckoutTitle(props) {
  const { t } = useTranslation();
  return (
    <>
      <p className="checkoutTitle">
        <span>
          {t("checkout_inst1.1") +
            ((props.tradeInfo?.user?.name && props.tradeInfo?.user?.name + ",") || t("checkout_inst1.2")) +
            t("checkout_inst1.3")}
        </span>
        <span className="checkoutTitle__amount">
          {" "}
          <FormatMoney type="total" />{" "}
        </span>
        <span>{t("checkout_inst1.4")}</span>
      </p>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutTitle);
