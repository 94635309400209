export const isProduction = () => {
  return ["checkout.luxtak.com", "checkoutv2.luxtak.com"].includes(window.location.hostname);
};

const getUrl = () => {
  return isProduction() ? "https://gateway.luxtak.com/" : "https://gateway.luxtak.com/";
};

export const url = getUrl();

const getSecUrl = () => {
  return isProduction() ? "https://security.luxtak.com" : "https://security-test.luxtak.com";
};

export const secUrl = getSecUrl();

export const setOpenpayAcoount = (region) => {
  let config = {
    MEX: {
      test: { id: "mjoq965x33ul3gxf8vpt", key: "pk_a77e298689a84495b4611a51b6df1f4c" },
      prod: { id: "mtg8r6gnf6jgta6n1uq4", key: "pk_008ba891db984727b78bb70d41577247" },
    },
    EUP: {
      test: { id: "mjoq965x33ul3gxf8vpt", key: "pk_a77e298689a84495b4611a51b6df1f4c" },
      prod: { id: "mtg8r6gnf6jgta6n1uq4", key: "pk_008ba891db984727b78bb70d41577247" },
    },
    PER: {
      test: { id: "my5pc64i0actv6fepiiq", key: "pk_bb78756210cd406a87c577a72f16de69" },
      prod: { id: "molbwtxggohymt9gortf", key: "pk_8383c8564a314a0c956047b28d5d368f" },
    },
  };

  if (isProduction()) {
    window.OpenPay.setId(config[region].prod.id);
    window.OpenPay.setApiKey(config[region].prod.key);
  } else {
    window.OpenPay.setSandboxMode(true);
    window.OpenPay.setId(config[region].test.id);
    window.OpenPay.setApiKey(config[region].test.key);
  }
};

export const getGoogleRecaptchaId = () => {
  return "6LeOBjMiAAAAAFm0au20do250VXfMXcZouw8wI_L";
};
