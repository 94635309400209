function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return "";
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " ")) ?? "";
}

const returnUrlAsParam = () => {
  let returnUrl = getParameterByName("return_url");
  return returnUrl ? `&return_url=${encodeURIComponent(returnUrl)}` : "";
};



export { getParameterByName as default, returnUrlAsParam };
