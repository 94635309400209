import React from "react";
import "./Failed.scss";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import LottiAnimation from "../../comp/LottiAnimation";

function Failed(props) {
  const { t } = useTranslation();
  return (
    <div className="failed">
      <LottiAnimation anime={props.method === "CreditCard" ? "cardFail" : "fail"} className={"failed__result"} />
      <p className="failed__notice1" style={{ color: "#ed5d6b" }}>
        {props.tradeStatus?.text ? t(props.tradeStatus?.text) : t("failed")}
      </p>
      <p className="failed__notice2">
        {props.tradeStatus?.notice ? t(props.tradeStatus?.notice) : t(props.payData?.code)}
      </p>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    payData: state.payData,
    tradeStatus: state.tradeStatus,
    method: state.method,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Failed);
