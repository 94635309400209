import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import InputAdornment from "@material-ui/core/InputAdornment";
import Correct from "../../img/correct.jpg";
import Flags from "../../img/flags.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import countryList from "../../utils/regionCodes";
import "./MobilePhone.scss";
import { connect } from "react-redux";
import { formatPhone } from "../../utils/formats";
import { checkPhone, checkIsPhoneValid } from "../../utils/validations";
var classNames = require("classnames");

function MobilePhone({ form, setForm, mobile, setFocusOnInput = () => {}, isGlobal, ...props }) {
  const tradeInfo = props.tradeInfo;
  isGlobal = isGlobal || form.isGlobal;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [dropDown, setDropDown] = useState(false);

  useEffect(() => {
    if (!form.phone.checked && !isGlobal) {
      const code = form.phone?.countryObj?.alpha2_code;

      let defaultPhone = tradeInfo?.user?.phone ? tradeInfo?.user?.phone : localStorage?.getItem("__frm_phone");

      if (checkIsPhoneValid(defaultPhone, code)) {
        setForm((prev) => {
          return {
            ...prev,
            phone: {
              ...prev.phone,
              value: formatPhone(defaultPhone, code, true),
              valid: true,
              checked: true,
            },
          };
        });
      }
    }
  }, [isGlobal, form.phone?.countryObj?.alpha2_code]);

  useEffect(() => {
    if (!form.phone.checked && isGlobal) {
      const code = props.geoLocation?.alpha2_code?.toUpperCase();
      const obj = countryList.find((item) => item?.alpha2_code === code);
      if (obj) {
        setForm((prev) => {
          return {
            ...prev,
            phone: {
              ...prev.phone,
              countryObj: obj,
              value: obj.phone_code,
              valid: true,
              checked: false,
            },
          };
        });
      }
    }
  }, [props.geoLocation]);

  document.body.onclick = () => {
    setDropDown(false);
  };

  return (
    <div className="mobilePhone homeMobile__filling__content__input">
      <TextField
        id={mobile ? "inputform-phone-mob" : "inputform-phone"}
        onFocus={() => {
          setFocusOnInput(true);
          setDropDown(false);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        size="small"
        inputProps={{ inputMode: "numeric", autocomplete: "new-password", "ga-data": "340" }}
        error={!form.phone.valid}
        helperText={!form.phone.valid && t("invalid_phone")}
        label={t("phone")}
        style={{ width: "100%" }}
        variant="outlined"
        value={form.phone.value}
        onChange={(event) => {
          let value = event.target.value;
          if (value?.length > 20) {
            value = value.slice(0, 20);
          }
          if (!value?.startsWith(form.phone.countryObj.phone_code)) {
            value = form.phone.countryObj.phone_code;
          }
          value = formatPhone(value, form.phone.countryObj.alpha2_code);
          setForm((prev) => {
            return {
              ...prev,
              phone: { ...prev.phone, value: value },
            };
          });
        }}
        onBlur={() => {
          checkPhone({ form: form, setForm: setForm, region: form.phone.countryObj.alpha2_code });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={Correct}
                alt=""
                style={{
                  display: form.phone.valid && form.phone.checked ? "block" : "none",
                }}
              />
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
              <div
                className="mobilePhone__flagContainer"
                onClick={() => {
                  isGlobal && setDropDown((prev) => !prev);
                }}
              >
                <div
                  style={{ backgroundImage: `url(${Flags})` }}
                  className={classNames("mobilePhone__flag", {
                    [form.phone.countryObj?.alpha2_code?.toLowerCase?.()]: form.phone.countryObj?.alpha2_code,
                  })}
                ></div>
                {isGlobal && <div className={classNames("mobilePhone__arow", { "arrow--up": dropDown })}></div>}
              </div>
            </InputAdornment>
          ),
        }}
      />
      <div
        className="mobilePhone__countrySelect"
        style={{ display: dropDown ? "block" : "none" }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Autocomplete
          value={form.phone.countryObj}
          onChange={(event, newValue) => {
            newValue.phone_code &&
              setForm((prev) => {
                return {
                  ...prev,
                  phone: {
                    ...prev.phone,
                    countryObj: newValue,
                    value: newValue.phone_code,
                    valid: true,
                    checked: false,
                  },
                };
              });
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={countryList}
          getOptionLabel={(option) => option.country_name}
          renderOption={(option) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{ backgroundImage: `url(${Flags})`, marginRight: "10px", marginBottom: "5px" }}
                className={`mobilePhone__flag ${option.alpha2_code?.toLowerCase?.()}`}
              ></div>
              {option.country_name} {option.phone_code}
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Search"}
              variant="outlined"
              // error={!form.state.valid}
              // helperText={!form.state.valid && t("invalid_state")}
              size="small"
              inputProps={{ ...params.inputProps, autocomplete: "new-password" }}
            />
          )}
          onFocus={() => {
            setFocusOnInput(true);
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onBlur={() => {
            // checkState({ form: form, setForm: setForm });
          }}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    geoLocation: state.geoLocation,
    tradeInfo: state.tradeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MobilePhone);
